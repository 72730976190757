import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./store/context/UserContext";
import { connect } from "react-redux";
import "./components/SuperResponsiveTableStyle.css";
import { Navigate, Route, Routes } from "react-router-dom";
import "toastr/build/toastr.min.css";
import HorizontalLayout from "./components/HorizontalLayout/";
import { openRoutes, userRoutes } from "./routes/allRoutes";
import "./assets/scss/module.scss";
import "./assets/scss/theme.scss";

import { changeTopbarTheme } from "./store/actions";
import { I18nContext } from "./store/context/i18nContext";
import Pages404 from "./pages/Utility/pages-404";
import { FaSpinner } from "react-icons/fa";
import { getUtmParams } from "./util/utm";
const App = () => {
  const { isLoggedIn } = useContext(UserContext);
  const [rerender, setRerender] = useState(false);

  const [utm, setUtm] = useState({});

  useEffect(() => {
    const params = getUtmParams();

    const hasAnyUtmParam = Object.values(params).some(value => value !== "");

    if (hasAnyUtmParam) {
      localStorage.setItem('utmParams', JSON.stringify(params));
      setUtm(params);
    }
  }, []);

  function getLayout() {
    let layoutCls = HorizontalLayout;
    changeTopbarTheme("dark");
    return layoutCls;
  }

  useEffect(() => {
    setRerender(prev => !prev);
  }, [isLoggedIn]);

  const Layout = getLayout();
  return (
    <React.Fragment>
      <Routes>
        {openRoutes.map(({ path, Component }, key) => (
          <Route path={path} element={<Component />} key={key} />
        ))}
        {userRoutes.map(({ path, Component }, key) => (
          <Route
            path={path}
            element={
              localStorage.getItem("BearerToken") ? (
                isLoggedIn ? (
                  <Component />
                ) : (
                  <div style={{ width: '100%', minHeight: 'calc(100vh - 340px)' }} className="d-flex flex-column justify-content-center align-items-center w-100 flex-1  fv_ondato_rule">
                    <FaSpinner size={14} className="spinner" />
                  </div>
                )
              ) : (
                <Navigate to="/" />
              )
            }
            key={key}
          />
        ))} 
        <Route path="*" element={<Pages404 />} />

      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

// {/* <Switch>
// { authRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={NonAuthLayout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={false}
//   />
// ))}

// { userRoutes.map((route, idx) => (
//   <Authmiddleware
//     path={route.path}
//     layout={Layout}
//     component={route.component}
//     key={idx}
//     isAuthProtected={true}
//     exact
//   />
// ))}
// </Switch> */}
