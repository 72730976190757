import React from 'react';
import { Button } from 'reactstrap'; // Make sure to import Button from your UI library
import { ThreeBounce } from 'better-react-spinkit';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from '../../store/context/TranslationContext';

export default function SubmitButton({  onClick, loading }) {

  const nextButtonStyle = {
    backgroundColor: '#1678AE',
    height: "40px",
    padding: "2px 4px 2px 4px",
    fontSize: "16px",
    width: "90px",
    borderRadius: "10px"
  }

  const {t} = useTranslation()


  if (loading) {
    return (
      <Button
        style={nextButtonStyle}
        variant="primary"
        disabled
        onClick={onClick}
      >
        <ThreeBounce size={10} color="#FFFFFF" />
      </Button>

    );
  }
  return (
    <Button
      style={nextButtonStyle}
      variant="primary"
      onClick={onClick}
    >
      {t("Submit")}
    </Button>
  );
}
