export function isArrayNonEmpty(param) {
  return Array.isArray(param) && param.length > 0;
}
export function arrayToCommaSeperatedString(param) {
  if (!isArrayNonEmpty(param)) {
    return param;
  }
  return param.join(', ');
}
export function processApiResponseForFile(response, defaultFileName) {
  const contentDisposition = response.headers.get('Content-Disposition');
  let filename = defaultFileName;
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  const blob = response?.data;
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
export function assignValueToArray(array, index, value) {
  if (array === null) {
    array = [];
  }
  while (array.length <= index) {
    array.push({});
  }
  array[index] = value;
}

export const multiplyBy10ToPower = (value, power = 2) => {
  return value * Math.pow(10, power);
};

export function handleShowIfCondition(data, showIf) {
  const { field, condition, value, required } = showIf;

  const fieldParts = field.split('.');

  let fieldValue = data;
  for (let part of fieldParts) {
    if (fieldValue === undefined || fieldValue === null) {
      return false;
    }
    fieldValue = fieldValue[part];
  }
  if (required) {
    switch (condition) {
      case '==':
        return fieldValue == value;
      case '!=':
        return fieldValue != value;
      case '>':
        return fieldValue > value;
      case '<':
        return fieldValue < value;
      case '>=':
        return fieldValue >= value;
      case '<=':
        return fieldValue <= value;
      case '===':
        return fieldValue === value;
      case '!==':
        return fieldValue !== value;
      default:
        return false;
    }
  } return true

}

export const getOrdinalSuffix = (index) => {
  const j = index % 10,
    k = index % 100;
  if (j === 1 && k !== 11) {
    return `${index}st`;
  }
  if (j === 2 && k !== 12) {
    return `${index}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${index}rd`;
  }
  return `${index}th`;
};

export const handleUtilLocalStorageClear = () => {
  const unauthUrl = localStorage.getItem('unauthUrl');
  const error = localStorage.getItem('error');
  const utmParams = localStorage.getItem('utmParams');
  localStorage.clear();
  if (utmParams) localStorage.setItem('utmParams', utmParams);
  if (error) localStorage.setItem('error', error);
  if (unauthUrl) localStorage.setItem('unauthUrl', unauthUrl);
}

// Detect if a string appears to contain HTML tags.
const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

/**
 * Translates a label using function t.
 * - If the label is not a string (e.g. already a React element), it is returned unchanged.
 * - If the string appears to be plain text, it is simply passed to t().
 * - If it contains HTML, you have two choices:
 *    Option 1: Return it unchanged (i.e. skip translation for HTML).
 *    Option 2: Parse the HTML, translate text nodes, and reassemble.
 *
 * Here we provide Option 2 as an example.
 */
export const translateLabel = (label, t) => {
  // If not a string, assume it’s already a React element or similar.
  if (typeof label !== 'string') {
    return label;
  }

  // For plain text, simply translate.
  if (!isHTML(label)) {
    return t(label);
  }

  // For HTML content, parse and translate text nodes.
  try {
    // Create a DOMParser instance (this is browser-only).
    const parser = new DOMParser();
    const doc = parser.parseFromString(label, 'text/html');

    // Use a TreeWalker to walk through all text nodes.
    const walker = doc.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);
    let node;
    while ((node = walker.nextNode())) {
      // Trim whitespace; if non-empty, translate the text.
      const trimmedText = node.nodeValue.trim();
      if (trimmedText) {
        // Replace the text node value with the translated text.
        // (You might need to consider preserving leading/trailing whitespace.)
        node.nodeValue = node.nodeValue.replace(trimmedText, t(trimmedText));
      }
    }
    // Return the inner HTML of the body.
    return doc.body.innerHTML;
  } catch (error) {
    console.error('Error parsing HTML for translation:', error);
    // If parsing fails, return the original label.
    return label;
  }
};

export const translatedOptions = (options, t) =>
  options?.map((option) => ({
    ...option,
    label: translateLabel(option?.label, t),
  }));



