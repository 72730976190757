import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
// import businessPrimeJSON from '../../../../BusinessPrime.json';
import Logo from "../../../../assets/images/Logo.svg";
import { customValidators } from '../../../../components/InputField/WrapperField';
import { shouldShowField } from '../../../../components/InputField/helper';
import { DialogContext } from '../../../../store/context/DialogContext';
import { doGET, doPUT } from '../../../../util/HttpUtil';
import AboutBusiness from './components/AboutBusiness';
import AccountActivity from './components/AccountActivity';
import AssetsInvolvement from './components/AssetsInvolvement';
import BusinessType from './components/BusinessType';
import ComplianceDetails from './components/ComplianceDetails';
import ConsoleAccordion from './components/ConsoleAccordion';
import CorporateAccountUpgrade from './components/CorporateAccountUpgrade';
import Documents from './components/DocumentsPages';
import FundFamilyOffices from './components/FundFamilyOffices';
import MoneyServicesBusiness from './components/MoneyServicesBusiness';
import { calculateNextStep, calculatePrevStep, convertArrayToDict } from './helper';
import ThankYou from './components/ThankYou';
import StartAgain from './components/StartAgain';
import { Button } from 'reactstrap';
import { ThreeBounce } from 'better-react-spinkit';
import "./styles.css"
import ExtraQuestions from '../ExtraQuestions';
import ApplicationDocRedo from '../components/IndividualLite/ApplicationDocRedo';
import { AskMoreMessage } from '../components';
import { handleUtmFormSubmit } from '../../../../util/utm';


const COMPONENTS = {
  BusinessType,
  AboutBusiness,
  AccountActivity,
  MoneyServicesBusiness,
  AssetsInvolvement,
  ComplianceDetails,
  FundFamilyOffices,
  CorporateAccountUpgrade,
  Documents,
  ThankYou: () => <ThankYou type="Business Prime" />,
  StartAgain,
  ExtraQuestions,
  AskMoreMessage,
  ApplicationDocRedo
};


const ApplyBusinessPrime = ({ asLabel, editMode = false, formId, refreshId, onChange = () => { } }) => {
  const { edit_id } = useParams();
  const [data, setData] = useState({});
  const [showErr, setShowErr] = useState(false);
  const [errors, setErrors] = useState({});
  const { showError } = useContext(DialogContext);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [step, setStep] = useState({ wizard: "BusinessType" });
  const [editId, setEditID] = useState(edit_id ?? formId);
  const [hasError, setHasError] = useState(false);
  const [validationkeyValue, setValidationKeyValue] = useState({});
  const [businessPrimeValidationFields, setBusinessPrimeValidationFields] = useState([]);
  const [applicationSteps, setApplicationSteps] = useState({});
  const [applicationForm, setApplicationForm] = useState({});

  const pageNumberRef = useRef(1);

  const validateField = (jsonField, value) => {
    let error = "";
    if (jsonField?.required && (value === undefined || value === null || value === "" || value?.length == 0)) {
      error = jsonField?.errorTypes?.required || jsonField.error || "This field is required.";
    } else if (jsonField?.min && value?.length < jsonField.min) {
      error = jsonField?.errorTypes?.length || `Minimum length is ${jsonField?.min}.`;
    } else if (jsonField?.regex && !new RegExp(jsonField?.regex).test(value)) {
      error = jsonField?.errorTypes?.format || jsonField.error || "Invalid format.";
    } else if (jsonField.max && value?.length > jsonField.max) {
      error = jsonField?.errorTypes?.length || `Maximum length is ${jsonField?.max}.`;
    }
    // Custom validator logic
    if (jsonField.validator && customValidators?.[jsonField.validator]) {
      const customError = customValidators[jsonField.validator](data, jsonField.field, value);
      if (customError) {
        error = customError;
      }
    }
    return error;
  }

  // TO CALCULATE INITIAL REQUIRED ERRORS FOR EMPTY FIELDS, AND FOR CONDITIONAL FIELDS RENEDRING WITH CHANGE OF DATA
  useEffect(() => {
    const initialErrors = {};
    businessPrimeValidationFields.map((field) => {
      if (
        field.required &&
        field?.wizard === step?.wizard &&
        shouldShowField(data, field.showIf) &&
        (data[field.field] === undefined || data[field.field] === null || data[field.field] === "")
      ) {
        // Check if the value is not a valid false boolean
        if (data[field.field] !== false && !errors?.[field.field]) {
          initialErrors[field.field] = field.errorTypes?.required || field.error || "This field is required.";
        }
      }
    });
    const shownErrors = {};
    businessPrimeValidationFields.map((field) => {
      if (field?.wizard === step?.wizard && shouldShowField(data, field.showIf)) {
        const error = validateField(field, data[field.field])
        if (error) {
          shownErrors[field.field] = error;
        }
      }
    });
    setErrors({ ...shownErrors, ...initialErrors });
  }, [data, step, businessPrimeValidationFields]);

  // TO GET BUSINESS PRIME FIELD JSON FROM API, AND SAVE IT IN A STATE
  const getValidationJSON = async () => {
    try {
      const response = await doGET("/api/o/cyclos/fields-json?group=BusinessPrime");
      if (response.status === 200) {
        // const bPrimeJSON = businessPrimeJSON;
        const bPrimeJSON = response?.data ?? {};
        setBusinessPrimeValidationFields(bPrimeJSON?.fields);
        const fieldsMap = (bPrimeJSON?.fields)?.reduce((acc, field) => {
          if (field?.field) {
            acc[field.field] = field;
          }
          return acc;
        }, {});
        setValidationKeyValue(fieldsMap);
      }

    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    getValidationJSON();
  }, []);

  useEffect(() => {
    if (formId) {
      setEditID(formId);
    }
  }, [formId]);

  const handleChange = (value) => {
    setData(value)
  };

  // TO CHECK IF THERE IS AN ERROR IN ANY FIELD, IF YES DISPLAY IT SEQUENCE WISE 
  const checkNext = () => {
    setShowErr(true);
    for (const field of businessPrimeValidationFields) {
      const fieldError = errors[field.field];
      if (fieldError) {
        showError(fieldError);
        return false;
      }
    }
    setErrors({});
    return true;

  };

  const handleSubmit = async ({ isLastStep }) => {
    if (checkNext()) {
      setErrors({});
      const nextStep = calculateNextStep(data, step?.wizard, editMode)
      await handleUpdate({ nextStep: nextStep, data, isLastStep });
      setShowErr(false);
    }
  };

  const handlePrev = (v) => {
    setErrors({})
    let prevStep = calculatePrevStep(data, step?.wizard)
    pageNumberRef.current = pageNumberRef.current - 1
    setStep(prevStep);
  };

  const checkIsAskForInfo = (steps) => {
    return (steps ?? [])?.find((v) => v?.status == 2)
  }

  const fetchDetail = async (stepProp) => {
    setDataLoading(true);
    try {
      if (editId) {
        localStorage.setItem("formId", editId);
        const response = await doGET(`/api/business-prime/detail?id=${editId}`);
        let businessPrimeResponse = response.data ?? {}
        const applicationResponse = await doGET(`/api/application-form/detail?id=${editId}&withExtension=true`)
        if (applicationResponse?.status == 200) {
          const applicationFormResponse = applicationResponse?.data
          setApplicationForm(applicationResponse?.data)
          if (applicationFormResponse?.steps?.length) {
            const askInfoStep = checkIsAskForInfo(applicationFormResponse?.steps)
            const stepsObj = convertArrayToDict(applicationFormResponse?.steps)
            setApplicationSteps(stepsObj);
            if (
              !asLabel
              && !editMode
              && stepsObj["Document Verification"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Document Verification"]?.status)
              && stepsObj["Application Form"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Application Form"]?.status)
            ) {
              setStep({ wizard: "ThankYou" });
            }

            if (!asLabel && !editMode && ((askInfoStep?.status == 2 && stepsObj["Document Verification"]?.status == 2)
              || stepsObj["Application Form"]?.status > 0 && (stepsObj["Document Verification"]?.status == 0)
            )) {
              setStep({ wizard: "ApplicationDocRedo" });
            } else if (!asLabel && !editMode && ((response?.data?.asks?.length && askInfoStep?.status == 2 && stepsObj["Application Form"]?.status == 2)
              || stepsObj["Application Form"]?.status > 0 && (stepsObj["Document Verification"]?.status == 0)
            )) {
              setStep({ wizard: "AskMoreMessage" });
            }
          }
        }
        setErrors({});
        setData(prev => ({ ...prev, ...response?.data }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
      if (stepProp) setStep(stepProp);
    }
  }

  useEffect(() => {
    fetchDetail();
  }, [editId, refreshId]);

  const handleUpdate = async ({ nextStep, payload, isfetchDetail, isLastStep = false }) => {
    if (editId) {
      setLoading(true);
      setHasError(false);
      try {
        if (!asLabel) {
          const response = await doPUT(`/api/business-prime/update`, payload ?? { ...data, _id: editId });
          if (response?.status == 200) {
            setData(prev => ({ ...prev, ...response?.data }));

            if (isLastStep && !asLabel && !editMode) {
              const submitResponse = await doGET(`/api/business-prime/submit?id=${editId}&markFormSubmit=true&markDocSubmit=true`)
              if (submitResponse?.status == 200) {
                handleUtmFormSubmit({
                  formType: "BUSINESS_PRIME", utmPayload: {
                    utm_source: applicationForm?.extension?.utm_source,
                    utm_medium: applicationForm?.extension?.utm_medium,
                    utm_campaign: applicationForm?.extension?.utm_campaign,
                    utm_content: applicationForm?.extension?.utm_content,
                    utm_term: applicationForm?.extension?.utm_term,
                  }
                });
                if (nextStep) {
                  pageNumberRef.current = 1;
                  await fetchDetail(nextStep);
                  setLoading(false);
                }
              }
            } else {
              pageNumberRef.current = pageNumberRef.current + 1;
              setLoading(false);
              setStep(nextStep);
            }
          }
        }
      } catch (error) {
        setHasError(true);
        showError(error);
      } finally {
        setLoading(false);
      }
    } else {
      if (nextStep) {
        setStep(nextStep);
      }
    }
  };

  const logout = async () => {
    try {
      const response = await doGET("/auth/logout");
      if (response?.status == 200) {
        const unauthUrl = localStorage.getItem('unauthUrl');
        localStorage.clear();
        if (unauthUrl) localStorage.setItem('unauthUrl', unauthUrl);
        window.location = "/"
      }
    } catch (error) {
      showError(error)
    }
  }

  const renderAsAccordion = () => {
    const selectedBusinesses = data?.businesses || [];
    const msbCheck = selectedBusinesses?.includes("MSB_No_Crypto");
    const cryptoExchangeCheck = selectedBusinesses?.includes("Crypto_Exchange_OTC_Liquidity_Remittance_FX_MSB");
    const fundAndFamilyCheck = selectedBusinesses?.includes("Fund_Family_Office");
    const accountUpgradeCheck = selectedBusinesses?.includes("Corporate_Higher_Limits_No_Client_Funds");

    const commonProps = {
      decrementStep: handlePrev,
      recordId: edit_id,
      bPrimeJSON: validationkeyValue,
      data,
      handleChange,
      pageNum: pageNumberRef.current,
      loading,
      step,
      handleSubmit,
      setErrors,
      showErr,
      asLabel: true && !editMode,
      editMode: editMode,
      hasError,
    };


    const extraQuestionsProps = {
      dataLoading: loading || dataLoading,
      formId: edit_id,
      formType: "BUSINESS_PRIME",
      onSubmit: () => { fetchDetail({ wizard: "ThankYou" }) },
      questionIds: data?.asks?.map((v) => v?.question_id).filter(Boolean) ?? []
    }

    const steps = [
      <BusinessType label="Business Type" {...commonProps} />,
      <AboutBusiness label="About business" {...commonProps} />,
      <AccountActivity label="Expected Account Activity"{...commonProps} />,
      (msbCheck || cryptoExchangeCheck) && <MoneyServicesBusiness label="Money Services Business" {...commonProps} />,
      (cryptoExchangeCheck) && <AssetsInvolvement label="Cryptocurrency and Digital Assets Involvement" {...commonProps} />,
      (fundAndFamilyCheck) && <FundFamilyOffices label="Funds and Family Offices" {...commonProps} />,
      (accountUpgradeCheck) && <CorporateAccountUpgrade label="Corporate Account Upgrade" {...commonProps} />,
      (fundAndFamilyCheck || msbCheck || cryptoExchangeCheck) && <ComplianceDetails label="Compliance" {...commonProps} />,
      (data?.asks?.length) && <ExtraQuestions label="Extra Infromation" {...commonProps} {...extraQuestionsProps} />
    ];

    return <ConsoleAccordion steps={steps.filter(Boolean)} />;
  };


  const CurrentComponent = COMPONENTS[step.wizard];

  return (
    <div style={{ margin: "0px" }} className={!asLabel && !editMode ? 'd-flex justify-content-center align-items-center' : ""}>
      <Form className={asLabel || editMode ? "" : 'form-content '} noValidate>
        {dataLoading ? <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-1 fv_ondato_rule">
          <FaSpinner size={14} className="spinner" />
        </div>
          :
          <>

            <div className='d-flex flex-column justify-content-center align-items-center position-relative '>
              {asLabel || editMode ? <></> : <img
                src={Logo}
                alt="fv-bank-logo"
                height="55"
                style={{ marginBottom: "10px" }}
                className="logo logo-dark"
              />}

              {asLabel || editMode ? <></> : <div className="heading">{("Business Prime Account Upgrade")}</div>}
              {asLabel || editMode ? <></> : <div className='logout-btn-form'>

                <Button
                  className='btn btn-secondary me-3'
                  style={{
                    height: "40px",
                    padding: "2px 4px 2px 4px",
                    fontSize: "16px",
                    width: "90px",
                    borderRadius: "10px"
                  }}
                  onClick={logout}
                  disabled={loading}
                >
                  {loading ? <ThreeBounce color="#FFF" /> : ("Logout")}
                </Button>

              </div>}

            </div>
            {asLabel ? renderAsAccordion() : CurrentComponent && (
              <CurrentComponent
                decrementStep={handlePrev}
                recordId={edit_id}
                bPrimeJSON={validationkeyValue}
                asLabel={asLabel}
                data={data}
                handleChange={handleChange}
                pageNum={pageNumberRef.current}
                loading={loading}
                step={step}
                handleSubmit={handleSubmit}
                setErrors={setErrors}
                showErr={showErr}
                startAgain={() => {
                  pageNumberRef.current = 1;
                  setStep({ wizard: "BusinessType" });
                }}
                dataLoading={loading || dataLoading} // for extraquestions
                formId={edit_id} // for extraquestions
                formType={"BUSINESS_PRIME"} // for extraquestions
                remark={(data?.remark ?? "")} // for askMoreMessage
                onNext={() => {
                  setStep({ wizard: "ExtraQuestions" }) // for askMoreMessage
                }}
                onSubmit={() => { fetchDetail({ wizard: "ThankYou" }) }} // for extraquestions
                questionIds={data?.asks?.map((v) => v?.question_id).filter(Boolean) ?? []}  // for extraquestions
                type={"Business Prime"} // for ApplicationDocRedo
                hideContainerStyle // for ApplicationDocRedo
                title="Business Prime"
              />
            )}
          </>

        }

      </Form>
    </div>
  );
};

export default ApplyBusinessPrime;
