import React, { useState, useEffect } from "react";
import { CountrySelect } from "..";
import { doGET } from "../../util/HttpUtil";
import { useTranslation } from "../../store/context/TranslationContext";

export default function PhoneNumWithValidation({
  className,
  label = "Mobile Phone",
  value: initialValue,
  onChange,
  isDisabled,
  notifyParent,
  onError = () => { },
  showErr,
  inline,
  individual_id,
  required,
  borderNone,
  onClickRight,
  outline,
  inputStyle,
  group = "Individuals_Lite",
  countrySelectStyle,
  RightComponent,
  err,
  form_id,
  validateOnly = false,
  type,
  rightComponentStyle = {},
  placeholder = "Mobile Phone"
}) {
  const [value, setValue] = useState(initialValue || { countryCode: '', phone: '' });
  const [error, setError] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false)
const {t} = useTranslation();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setError(err);
  }, [err]);

  useEffect(() => {
    setHasError(showErr);
  }, [showErr]);


  const handleChange = async (newData) => {
    if (newData?.countryCode && newData?.countryCode != value?.countryCode) {
      await validatePhoneNumber({ newDataCountryCode: newData?.countryCode, focused: isFocused });
    }
    const updatedValue = { ...value, ...newData };
    setValue(updatedValue);
    onChange(updatedValue);
  };

  const validatePhoneNumber = async ({ newDataCountryCode, focused }) => {
    if(loading) return
    if (!value.phone || focused) {
      if (!value.phone) {
        if(required) {
          setError(`Please enter valid ${type == "landLine" ? "Landline" : "Mobile Phone"}`)
          onError({ errorMsg: `Please enter valid ${type =="landLine" ? "Landline" : "Mobile Phone"}`, hasError: false })
        } else {
          setError(null)
          onError({ errorMsg: "", hasError: false })
        }

      }
      return;
    }

    if(!value?.countryCode) return

    try {
      setLoading(true);
      const response = await doGET(`/api/${validateOnly ? "o/cyclos/validate/field" : "cyclos/verify/field"}?group=${group}&value=${value.phone}&field=${type == "landLine" ? "landLinePhone" : "mobilePhone"}&mobileCountryCode=${newDataCountryCode ?? value.countryCode}&form_id=${form_id}${individual_id ? `&individual_id=${individual_id}`  : ""}`);
      if (response.status === 204) {
        notifyParent(true);
        onError({ errorMsg: "", hasError: false })
        setError(null);
        setHasError(false);
      } else if (response.status === 200 && response.data) {
        setError(response.data);
        onError({ errorMsg: response.data, hasError: true })
        setHasError(true);
        notifyParent(false);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    validatePhoneNumber({ focused: isFocused })
  }, [value?.countryCode, value?.phone, validateOnly])


  return (
    <div className={`${className} ${error ? 'error' : ''} mt-2`}>
      {outline ??
        <div style={{ minWidth: "120px ", fontSize: 16, margin: "0px 0px 5px 5px", fontWeight: "500", color: "#495057" }}>
          {label}{required && <font color="red">*</font>}
        </div>
      }
      <div
        style={{ margin: "0px 0px 5px 0px", height: "36px" }}
        className={" input-group flex-1 "}
      >
        <div className="input-group-prepend">
          <CountrySelect
            value={value.countryCode}
            onChange={(v) => handleChange({ countryCode: v.value })}
            disabled={isDisabled}
            innerContainerStyle={{ borderRadius: "15px 0px 0px 15px", }}
            outline
            style={{ borderRadius: "15px 0px 0px 15px", ...countrySelectStyle }}
            className={`m-0 `}
            noLabel
            inputGroup
            showErr={showErr}
            required={required}
            // error={error}
            inline={inline}
            borderNone={borderNone}
            isDisabled={isDisabled}
            isd={true}
          />
        </div>
        <input
          style={{
            height: "50px",
            borderRadius: "0px 15px 15px 0px",
            paddingLeft: "5px",
            borderTop: "none",
            borderBottom: "none",
            borderRight: "none",
            borderTop: "none",
            fontSize: "14px",
            outline: "none",
            backgroundColor: "#F1FAFC",
            ...inputStyle
          }}
          type="number"
          className="form-control"
          placeholder={placeholder}
          value={value.phone}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value)) {
              handleChange({ phone: value });
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "e" || e.key === "E" || e.key === "+" || e.key === "-") {
              e.preventDefault();
            }
          }}

          onBlur={() => {
            setIsFocused(false)
            validatePhoneNumber({ focused: false })
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
          disabled={isDisabled}
          required
        />

      </div>
      {RightComponent && (
        <div
          onClick={(e) => (onClickRight ? onClickRight(e) : null)}
          style={{ height: "32px", marginRight: '0px', ...rightComponentStyle }}
          className={onClickRight ? "component_pointer inputfield__rightComponent" : "inputfield__rightComponent "}
        >
          {RightComponent()}
        </div>
      )}
      {(hasError && !loading && error) && <div className="error-message mt-3"> <em className="selectfield__error">{t(error)}</em></div>}
      {loading ? <div className="selectfield__info mt-3">{t("Validating Mobile Phone...")}</div> : null}

    </div>
  );
}
