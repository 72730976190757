import React from 'react';
import { Row } from 'reactstrap';
import { InputField, SelectField } from '../../../../../components';
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple';
import AntDateSelect from '../../../../../components/DateSelects/AntDateSelect';
import EmailWithValidation from '../../../../../components/Email/EmailWithValidation';
import { AddressAutocompleter } from '../../../../../components/InputField/AddressAutocompleter';
import PhoneNumWithValidation from '../../../../../components/PhoneNum/PhoneNumWithValidation';
import PincodeField from '../../../components/PincodeField/PincodeField';
import SSNField from '../../../components/SSNField/SSNField';
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import { addressDesc } from '../../constants';
import PercentageInput from './PercentageInput';
import { InputLabel } from './ProofOfDocument';
import "./style.css";
import { getDefaultValue } from '../../../components/PincodeField/helper';
const ACCESS_TYPES = [
    { id: 0, type: "Viewer", text: "View payments and create new beneficiaries." },
    { id: 1, type: "Basic User", text: "All access of Viewer and initiate new payments. Payments initiated by basic users will require authorisation from owner (or) approver (or) a power user before the payment is submitted for further processing. Basic user cannot authorise payments." },
    { id: 2, type: "Approver", text: "All access of Basic User and approve payments initiated by others. Approver’s own initiated transactions will require authorisation from another Approver, Power User or Owner." },
    { id: 3, type: "Power User", text: "Same access as Owner" },
]

const User = ({
    setCentralErrors,
    centralErrors,
    asLabel,
    data,
    handleChange,
    hasError,
    index,
    handleValidation,
    validationkeyValue,
    handleChangeViaObject
}) => {

    return (
        <div className='mt-2'>

            <QuestionRadioGroup
                field={`individuals[${index}].isShareHolder`}
                style={{ fontSize: 15 }}
                question="Is this person a shareholder of the entity?"
                data={data}
                handleChange={(field, value) => {
                    if (value) {
                        handleChange(`individuals[${index}].isIndependentDirector`, false)
                    }
                    handleChange(field, value)
                }}
                error={handleValidation('individuals[].isShareHolder', data?.individuals?.[index].isShareHolder)}
                hasError={hasError}
                // error={hasError}
                isDisabled={asLabel}
                required
            // defaultValue={index == 0 ? true : null}

            />

            <QuestionRadioGroup
                field={`individuals[${index}].isAuthorizedUser`}
                style={{ fontSize: 15 }}
                question="Is this person an authorized user of the entity?"
                data={data}
                handleChange={(field, value) => {
                    if (value) {
                        handleChange(`individuals[${index}].isIndependentDirector`, false)
                    }
                    handleChange(field, value)
                }}
                error={handleValidation('individuals[].isAuthorizedUser', data?.individuals?.[index].isAuthorizedUser)}
                hasError={hasError}
                // error={hasError}
                isDisabled={asLabel || index == 0}
                required
                defaultValue={index == 0 ? true : null}
            />


            {(!data?.individuals?.[index].isShareHolder && !data?.individuals?.[index].isAuthorizedUser) &&
                <QuestionRadioGroup
                    field={`individuals[${index}].isIndependentDirector`}
                    style={{ fontSize: 15 }}
                    question={validationkeyValue["individuals[].isIndependentDirector"]?.label}
                    data={data}
                    handleChange={handleChange}
                    error={handleValidation('individuals[].isIndependentDirector', data?.individuals?.[index].isIndependentDirector)}
                    hasError={hasError}
                    isDisabled={asLabel}
                    required
                />}

            {(data?.individuals?.[index].isShareHolder || data?.individuals?.[index].isAuthorizedUser || data?.individuals?.[index].isIndependentDirector) && <div>
                <InputLabel required label={"Name"} labelStyle={{ fontSize: 15 }} />
                <Row>
                    <InputField
                        className="col-sm-4"
                        placeholder="First"
                        value={data?.individuals?.[index]?.firstName}
                        onChange={(v) => handleChange(`individuals[${index}].firstName`, v)}
                        error={handleValidation("individuals[].firstName", data?.individuals?.[index]?.firstName)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                    <InputField
                        className="col-sm-4"
                        placeholder="Middle"
                        value={data?.individuals?.[index]?.middleName}
                        onChange={(v) => handleChange(`individuals[${index}].middleName`, v)}
                        error={handleValidation("individuals[].middleName", data?.individuals?.[index]?.middleName)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                    <InputField
                        className="col-sm-4"
                        placeholder="Last"
                        value={data?.individuals?.[index]?.lastName}
                        onChange={(v) => handleChange(`individuals[${index}].lastName`, v)}
                        error={handleValidation(`individuals[].lastName`, data?.individuals?.[index]?.lastName)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                    />
                </Row>

                <InputLabel required label={"Address"} labelStyle={{ fontSize: 15 }} />
                <Row>

                    <AddressAutocompleter
                        placeholder={("Street name")}
                        value={data?.individuals?.[index]?.address?.street}
                        onChange={(v) => handleChange(`individuals[${index}].address.street`, v)}
                        onSelect={(components) => {
                            handleChange(`individuals[${index}].address.buildingNumber`, components.buildingNumber);
                            handleChange(`individuals[${index}].address.street`, components.street);
                            handleChange(`individuals[${index}].address.pinCode`, components.pinCode);
                            handleChange(`individuals[${index}].address.city`, components.city);
                            handleChange(`individuals[${index}].address.state`, components.state);
                            handleChange(`individuals[${index}].address.country`, components.country);
                        }}
                        error={handleValidation(`individuals[].address.street`, data?.individuals?.[index]?.address?.street)}
                        showErr={hasError}
                        desc={(addressDesc["street"])}
                        isDisabled={asLabel}
                    />

                </Row>
                <Row>
                    <InputField
                        placeholder={("Building and Number")}
                        value={data?.individuals?.[index]?.address?.buildingNumber}
                        onChange={(v) => handleChange(`individuals[${index}].address.buildingNumber`, v)}
                        error={handleValidation(`individuals[].address.buildingNumber`, data?.individuals?.[index]?.address?.buildingNumber)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        maxLength={validationkeyValue['individuals[].address.buildingNumber']?.max}
                        desc={(addressDesc["buildingNumber"])}
                    />
                </Row>
                <Row>
                    <InputField
                        className="col-sm-6 col-md-3"
                        placeholder="City"
                        value={data?.individuals?.[index]?.address?.city}
                        onChange={(v) => handleChange(`individuals[${index}].address.city`, v)}
                        error={handleValidation(`individuals[].address.city`, data?.individuals?.[index]?.address?.city)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        maxLength={validationkeyValue['individuals[].address.city']?.max}
                        desc={(addressDesc["city"])}
                    />
                    <InputField
                        className="col-sm-6 col-md-3"
                        placeholder="State / Province / Region"
                        value={data?.individuals?.[index]?.address?.state}
                        onChange={(v) => handleChange(`individuals[${index}].address.state`, v)}
                        error={handleValidation(`individuals[].address.state`, data?.individuals?.[index]?.address?.state)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        maxLength={validationkeyValue['individuals[].address.state']?.max}
                        desc={(addressDesc["state"])}
                    />

                    {data?.individuals?.[index]?.address?.hidePinCode ? <></> :  <PincodeField
                        key={"pinCode"}
                        countryCode={data?.individuals?.[index]?.address?.country}
                        placeholder="Postal / Zip Code"
                        showErr={hasError}
                        maxLength={40}
                        className="col-sm-6 col-md-3"
                        isDisabled={asLabel}
                        value={data?.individuals?.[index]?.address?.pinCode}
                        onChange={(v) => handleChange(`individuals[${index}].address.pinCode`, v)}
                    />}

                    <SelectField
                        data={validationkeyValue["Business_Registration_Country"]?.options}
                        value={data?.individuals?.[index]?.address?.country}
                        onChange={(v) => {
                            handleChange(`individuals[${index}].address.country`, v?.value)
                            if (data?.individuals?.[index]?.ssn && !(["US", "USA"]?.includes(v?.value) || ["US", "USA"]?.includes(data?.individuals?.[index]?.nationality))) {
                                handleChange(`individuals[${index}].ssn`, null)
                            }

                            const defaultPincode = getDefaultValue(v?.value)
                            if (defaultPincode) {
                                handleChange(`individuals[${index}].address.hidePinCode`, true);
                                handleChange(`individuals[${index}].address.pinCode`, defaultPincode)
                            } else {
                                handleChange(`individuals[${index}].address.hidePinCode`, false);
                            }
                        }}


                        error={handleValidation('individuals[].address.country', data?.individuals?.[index]?.address?.country, data)}
                        showErr={hasError}
                        label={validationkeyValue["individuals[].address.country"]?.label}
                        placeholder={validationkeyValue["individuals[].address.country"]?.placeholder}
                        desc={(addressDesc["country"])}
                        isDisabled={asLabel}
                        noLabel
                        className="col-sm-6  col-md-3  with-margin-top"
                    />

                </Row>

                <Row>
                    <AntDateSelect
                        placeholder={"dd/mm/yyyy"}
                        className="col-sm-6"
                        format="dd/mm/yyyy"
                        label="Date of Birth"
                        value={data?.individuals?.[index]?.dob}
                        onChange={(v) => handleChange(`individuals[${index}].dob`, v)}
                        error={handleValidation("individuals[].dob", data?.individuals?.[index]?.dob)}
                        showErr={hasError}
                        isDisabled={asLabel}
                        required={true}
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))} // 18 years ago from today
                        minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 110))} // 110 years ago from today
                    />

                    <div className="col-12 col-sm-6">
                        <SelectField
                            data={validationkeyValue["individuals[].nationality"]?.options}
                            value={data?.individuals?.[index]?.nationality}
                            onChange={(v) => {
                                handleChange(`individuals[${index}].nationality`, v?.value);
                                if (!data?.individuals?.[index]?.countryOfIssuance) {
                                    handleChange(`individuals[${index}].countryOfIssuance`, v?.value)
                                }
                                if (data?.individuals?.[index]?.ssn && !(["US", "USA"]?.includes(v?.value) || ["US", "USA"]?.includes(data?.individuals?.[index]?.address?.country))) {
                                    handleChange(`individuals[${index}].ssn`, null);
                                }
                            }}
                            error={handleValidation('individuals[].nationality', data?.individuals?.[index]?.nationality, data)}
                            showErr={hasError}
                            label={validationkeyValue["individuals[].nationality"]?.label}
                            placeholder={validationkeyValue["individuals[].nationality"]?.placeholder}
                            isDisabled={asLabel}
                        />
                    </div>


                    <EmailWithValidation
                        group="Businesses_Lite"
                        label={"Email"}
                        placeholder={"Email"}
                        className="col-sm-6"
                        form_id={data?._id}
                        notifyParent={(v) => { }}
                        onError={(v) => {
                            setCentralErrors((prevErrors) => ({
                                ...prevErrors,
                                [`individuals[].email`]: v,
                            }));
                        }}
                        value={data?.individuals?.[index]?.email}
                        type="email"
                        onChange={(v) => handleChange(`individuals[${index}].email`, v)}
                        err={handleValidation(`individuals[].email`, data?.individuals?.[index]?.email)}
                        showErr={hasError}
                        isDisabled={asLabel || index == 0}
                        required
                    />

                    <PhoneNumWithValidation
                        group="Businesses_Lite"
                        label="Mobile Phone"
                        className="col-sm-6 mt-2"
                        form_id={data?._id}
                        individual_id={data?.individuals?.[index]?._id}
                        validateOnly={!data?.individuals?.[index].isAuthorizedUser}
                        value={{
                            phone: data?.individuals?.[index]?.mobilePhone,
                            countryCode: data?.individuals?.[index]?.mobileCountryCode,
                        }}
                        onChange={(v) => {
                            handleChangeViaObject({
                                [`individuals[${index}].mobilePhone`]: v?.phone,
                                [`individuals[${index}].mobileCountryCode`]: v?.countryCode,
                            })
                        }}
                        isDisabled={asLabel}
                        notifyParent={() => { }}
                        onError={(v) => {
                            setCentralErrors((prevErrors) => ({
                                ...prevErrors,
                                [`individuals[].mobilePhone`]: v,
                            }));
                        }}

                        required={validationkeyValue['individuals[].mobilePhone']?.required}
                        err={handleValidation('individuals[].mobilePhone', data?.individuals?.[index]?.mobilePhone)}
                        showErr={hasError}
                    />




                    <InputField
                        className="col-sm-6"
                        label={"TAX ID or Passport Number"}
                        placeholder={"TAX ID or Passport Number"}
                        value={data?.individuals?.[index]?.taxId}
                        onChange={(v) => handleChange(`individuals[${index}].taxId`, v)}
                        error={handleValidation(`individuals[].taxId`, data?.individuals?.[index]?.taxId)}
                        showErr={hasError}
                        type="text"
                        isDisabled={asLabel}
                        required
                    />

                    <SelectField
                        data={validationkeyValue["individuals[].countryOfIssuance"]?.options}
                        value={data?.individuals?.[index]?.countryOfIssuance}
                        onChange={(v) => handleChange(`individuals[${index}].countryOfIssuance`, v?.value)}
                        error={handleValidation('individuals[].countryOfIssuance', data?.individuals?.[index]?.countryOfIssuance, data)}
                        showErr={hasError}
                        label={validationkeyValue["individuals[].countryOfIssuance"]?.label}
                        placeholder={validationkeyValue["individuals[].countryOfIssuance"]?.placeholder}
                        isDisabled={asLabel}
                        className="col-sm-6"
                        required
                    />




                    {data?.individuals?.[index]?.isAuthorizedUser ? <SelectField
                        labelStyle={{ fontSize: 15 }}
                        className="col-sm-6"
                        label="Role"
                        placeholder="Role"
                        data={validationkeyValue["individuals[].role"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                        isDisabled={asLabel}
                        value={data?.individuals?.[index]?.role}
                        onChange={(v) => handleChange(`individuals[${index}].role`, v?.value)}
                        error={handleValidation(`individuals[].role`, data?.individuals?.[index]?.role)}
                        showErr={hasError}
                        required
                    /> : null}

                    {data?.individuals?.[index]?.isAuthorizedUser ? <SelectField
                        labelStyle={{ fontSize: 15 }}
                        className="col-12 col-sm-6"
                        label="Account Access Type"
                        data={validationkeyValue["individuals[].accessType"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                        isDisabled={asLabel || index == 0}
                        placeholder="Select Account Accesss Type"
                        value={data?.individuals?.[index]?.accessType}
                        onChange={(v) => handleChange(`individuals[${index}].accessType`, v?.value)}
                        error={handleValidation(`individuals[].accessType`, data?.individuals?.[index]?.accessType)}
                        showErr={hasError}
                        required
                    /> : null}

                    {data?.individuals?.[index]?.isAuthorizedUser ? <div>
                        {ACCESS_TYPES.map(e => (<div className='my-2 ps-2 fs-6' key={e.id}><span className="bold-text">{e.type}: </span>{e.text}</div>))}
                    </div> : null}


                    {data?.individuals?.[index]?.isAuthorizedUser ?
                        <InputField
                            className="col-sm-6"
                            label={validationkeyValue["individuals[].Mother_Maiden_Name"]?.label}
                            placeholder={validationkeyValue["individuals[].Mother_Maiden_Name"]?.placeholder}
                            value={data?.individuals?.[index]?.Mother_Maiden_Name}
                            onChange={(v) => handleChange(`individuals[${index}].Mother_Maiden_Name`, v)}
                            error={handleValidation(`individuals[].Mother_Maiden_Name`, data?.individuals?.[index]?.Mother_Maiden_Name)}
                            showErr={hasError}
                            type="text"
                            isDisabled={asLabel}
                            required={validationkeyValue["individuals[].Mother_Maiden_Name"]?.required}
                        /> : null}

                </Row>

                <Row>
                    {data?.individuals?.[index]?.isShareHolder ? <PercentageInput
                        className="col-sm-6"
                        label={"Percentage of Ownership"}
                        value={data?.individuals?.[index]?.percentOwner || ""}
                        onChange={(e) => {
                            handleChange(`individuals[${index}].percentOwner`, e);
                        }}
                        isDisabled={asLabel}
                        error={handleValidation(`individuals[].percentOwner`, data?.individuals?.[index]?.percentOwner)}
                        showErr={hasError}
                        required
                    /> : null}


                    {(["USA", "US"]?.includes(data?.individuals?.[index]?.address?.country) || ["USA", "US"]?.includes(data?.individuals?.[index]?.nationality)) && (
                        <SSNField
                            value={data?.individuals?.[index]?.ssn}
                            onChange={(v) => handleChange(`individuals[${index}].ssn`, v)}
                            showErr={hasError}
                            required
                            isDisabled={asLabel}
                            err={handleValidation('individuals[].ssn', data?.individuals?.[index]?.ssn, data)}
                            className="col-sm-6 mt-0"
                            validateSSNFromCyclos={false}
                            notifyParent={() => { }}
                            group="Individuals_Lite"
                            applicationId={data?._id}
                        />
                    )}
                </Row>

                {(data?.individuals?.[index]?.isAuthorizedUser || data?.individuals?.[index]?.isShareHolder) ? <div className='mt-3'>
                    <QuestionRadioGroup
                        field={`individuals[${index}].haveControlOverEntity`}
                        style={{ fontSize: 15 }}
                        question={validationkeyValue["individuals[].haveControlOverEntity"]?.label}
                        data={data}
                        handleChange={handleChange}
                        isDisabled={asLabel}
                        //defaultValue={false}
                        error={handleValidation(`individuals[].haveControlOverEntity`, data?.individuals?.[index]?.haveControlOverEntity)}
                        hasError={hasError}
                        required
                    />

                    <QuestionRadioGroup
                        field={`individuals[${index}].isDirector`}
                        style={{ fontSize: 15 }}
                        question="Is this person a Director of the entity?"
                        data={data}
                        handleChange={handleChange}
                        error={handleValidation('individuals[].isDirector', data?.individuals?.[index].isDirector)}
                        hasError={hasError}
                        isDisabled={asLabel}
                        //defaultValue={false}
                        required

                    />

                </div> : null}

                {data?.individuals?.[index]?.isAuthorizedUser ? <div>
                    <QuestionRadioGroup
                        field={`individuals[${index}].isAuthorizedContact`}
                        style={{ fontSize: 15 }}
                        question="Is this person an authorized contact on the account?"
                        data={data}
                        handleChange={handleChange}
                        error={handleValidation('individuals[].isAuthorizedContact', data?.individuals?.[index].isAuthorizedContact)}
                        hasError={hasError}
                        isDisabled={asLabel}
                        required
                        desc="This is a person we can send account sensative inforation to. They may not be able to access the account but will receive notifications."
                    //defaultValue={false}
                    />

                    <QuestionRadioGroup
                        field={`individuals[${index}].isAgentWithAttorneyPower`}
                        style={{ fontSize: 15 }}
                        question="Is this person an agent of the company operating under a power of attorney?"
                        data={data}
                        handleChange={handleChange}
                        error={handleValidation('individuals[].isAgentWithAttorneyPower', data?.individuals?.[index].isAgentWithAttorneyPower)}
                        isDisabled={asLabel}
                        hasError={hasError}
                        required
                    />
                </div> : null}

                {data?.individuals?.[index]?.isShareHolder ? <Row>
                    <CheckBoxFieldMutiple
                        labelStyle={{ fontSize: 15 }}
                        label="Source(s) of Wealth"
                        data={validationkeyValue["individuals[].sourceOfWealthList"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                        placeholder="Select"
                        value={data?.individuals?.[index]?.sourceOfWealthList ? data?.individuals?.[index]?.sourceOfWealthList : []}
                        onChange={(v) => {
                            handleChange(`individuals[${index}].sourceOfWealthList`, v)
                        }}
                        error={handleValidation('individuals[].sourceOfWealthList', data?.individuals?.[index]?.sourceOfWealthList)}
                        showErr={hasError}
                        isDisabled={asLabel}
                        required
                        desc="Select all that apply. Note, you may be asked to provide evidence of your source of wealth."
                    />

                </Row> : null}
            </div>}
        </div>
    )
}

export default User;