import React, { useEffect, useState } from 'react';
import DocCategoryService from '../../pages/DocCategory/DocCategoryService';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { GroupedSelectField } from '../SelectField/GroupedSelectField';
import { useTranslation } from '../../store/context/TranslationContext';

function DocTypeSelect({ module = "", label, className = "", ...props }) {
    const [data, setData] = useState([]);
    const {t} = useTranslation();

    const fetchCategories = () => {
        if (DocCategoryService.originalData?.length) {
            setData(toJS(DocCategoryService.originalData)?.filter(record => module ? record.module === module : true));
        } else {
            DocCategoryService.fetchSingleton();
        }
    }

    useEffect(() => {
        fetchCategories()
    }, [module, DocCategoryService?.originalData]);



    // Transform the data to fit the format expected by GroupedSelectField
    const groupedOptions = DocCategoryService.transformData(data).map(category => ({
        label: t(category?.title),
        options: category?.children?.map(child => ({
            value: child.value,
            label: t(child.title),
            groupValue: category.value,
            groupLabel: t(category.title),
        }))
    }));

    return (
        <div className={className}>
            <label style={{ fontSize: "16px", fontWeight: "500" }}>{t(label)}</label>
            <GroupedSelectField
                data={groupedOptions}
                {...props}
            />
        </div>
    );
}

export default observer(DocTypeSelect);
