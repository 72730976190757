import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';

const TextArea = ({ label, id, placeholder, value, onChange, disabled }) => {
    return (
        <FormGroup>
            {label && <Label
                style={{ fontSize: "16px", margin: "0px 0px 5px 5px", fontWeight: "500", display: "block", }}
                for={id}>{label}</Label>}
            <Input
                type="textarea"
                name={id}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                maxLength="6000"
                rows={4}
                style={{
                    resize: 'vertical',
                    backgroundColor: "#F1FAFC",
                    borderRadius: '4px',
                    fontSize: "14px",
                    padding: '8px 13px',
                    borderRadius: "14px",
                    border: '0px solid #ced4da',
                    transition: 'border-color 0.2s ease-in-out'
                }}
            />
        </FormGroup>
    );
};

export default TextArea;
