import React from 'react';

const ThankYou = ({ type }) => {
    return (
        <div className='d-flex flex-column  justify-content-center  align-items-center  '>
            <div style={{ fontSize: "30px", lineHeight: 2, marginBottom: 10 }}>
                Thank you for submitting your application.
            </div>
            <div style={{ textAlign: "center", fontSize: "20px" }}>
                {("Thank you for providing the information. We will be in touch with you shortly. We appreciate your interest in opening an account with us.")}
            </div>
        </div>
    )
}

export default ThankYou