import React, { useEffect, useState, useContext, useRef } from 'react';
import QRCode from 'qrcode.react';
import { FaCopy, FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import "./styles.scss"
import clipboardCopy from 'clipboard-copy';
import { StatusWithBadge } from '../../thirdParty/ondato/Ondato';
import { doGET } from '../../../../util/HttpUtil';
import { DialogContext } from '../../../../store/context/DialogContext';
import { Button } from 'reactstrap';
import moment from 'moment';

const OndatoVerification = ({ formId, uboId, onComplete, onShowQRCode }) => {
    const { showError, showMessage } = useContext(DialogContext);
    let { individual_id } = useParams();

    const [loading, setLoading] = useState(false);
    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeURL, setQRCodeURL] = useState('');
    const [ondatoStatus, setOndatoStatus] = useState({});
    const [polling, setPolling] = useState(null);
    const pollingStartTimeRef = useRef(null);

    useEffect(() => {
        if (polling) {
            return () => clearInterval(polling);
        }
    }, [polling]);

    const checkOndatoStatus = async () => {
        if (formId && uboId) {
            try {
                const response = await doGET(`/api/ondato/form/status?id=${formId}&ubo_id=${uboId}&refresh=true`);
                setOndatoStatus(response.data);
                if (["Completed", "Approved", "Rejected",]?.includes(response?.data?.status) ) {
                    const pollingTime = moment(pollingStartTimeRef.current); // Use ref value
                    const modifiedUtc = moment(response?.data?.modifiedUtc);
                    if (pollingTime.isSameOrBefore(modifiedUtc)) {
                        if (polling) clearInterval(polling);
                        onComplete();
                    }
                }
            } catch (error) {
                // showError(error);
            }
        }
    };

    const startPolling = () => {
        pollingStartTimeRef.current = moment().toISOString(); // Use ref to store the current time

        const intervalId = setInterval(() => {
            checkOndatoStatus();
        }, 10000); 

        setPolling(intervalId);
    };

    const requestLink = async () => {
        setLoading(true);
        try {
            const userAgent = navigator.userAgent.toLowerCase();
            const isMobile = /android|iphone/.test(userAgent);
            const response = await doGET(`/api/kyc/qr/url?form_id=${formId}&ubo_id=${uboId}`);
            if (isMobile) {
                window.location.href = response.data;
            } else {
                setShowQRCode(true);
                setQRCodeURL(response.data);
                onShowQRCode(true);
                startPolling();
            }
        } catch (error) {
            // showError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyLink = () => {
        clipboardCopy(qrCodeURL);
        showMessage('Link copied to clipboard!');
    };

    return (
        <div className="d-flex flex-column align-items-center justify-content-center">

            {loading ? (
                <FaSpinner size={32} className="spinner" />
            ) : (
                <>
                    {(showQRCode || ["Completed", "Approved", "Rejected",]?.includes(ondatoStatus?.status) )? null : <Button className="fv_ubo_button" onClick={requestLink}>Complete KYC</Button>}
                    {showQRCode && (
                        <div style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px' }}>
                            
                                <>
                                    <p>Scan this QR code with your mobile device to proceed:</p>
                                    <QRCode value={qrCodeURL} size={256} level={"H"} includeMargin={true} />

                                    <div style={{ marginTop: '20px' }}>
                                        <p>Can't scan the QR Code?
                                            <a href={qrCodeURL} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>Complete KYC on browser</a>
                                            <FaCopy onClick={handleCopyLink} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                                        </p>
                                    </div>

                                </>
                            
                                {!["Completed", "Approved", "Rejected",]?.includes(ondatoStatus?.status) &&  <div className="mt-4 d-flex w-100 align-items-center justify-content-center">
                                    {ondatoStatus.status && (
                                    <StatusWithBadge title="Status" status={ondatoStatus.status ?? "Pending"} />
                                )}
                            </div>}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default OndatoVerification;
