import React, { useContext, useEffect, useState } from 'react';
import Logo from "../../../../../assets/images/Logo.svg";
import LanguagePicker from '../../../../../components/LanguagePicker/LanguagePicker';
import { useTranslation } from '../../../../../store/context/TranslationContext';
import RedirectToBusinessPrime from '../../BusinessLiteForms/RedirectToBusinessPrime';
import BusinessPlusKYCStatus from './BusinessPlusKYCStatus';
import { doGET } from '../../../../../util/HttpUtil';
import { DialogContext } from '../../../../../store/context/DialogContext';
import JourneySteps from './JourneySteps';

const ThankYou = ({ application_id, type }) => {
    const { t } = useTranslation()

    const { showError } = useContext(DialogContext)

    const [isAppliedForPrime, setIsAppliedForPrime] = useState(false);
    const [isPrimeApplicationSubmitted, setIsPrimeApplicationSubmitted] = useState(false);
    const [allKYCComplete, setAllKYCComplete] = useState(false);

    // Check if the user already has a BP application
    const checkIfUpgradeToPrime = async () => {
        try {
            const response = await doGET(`/api/application-form/grid?plus_id=${application_id}`);
            if (response?.status === 200 && response?.data?.rows?.length) {
                setIsAppliedForPrime(true);
                const applicationFormStep = response?.data?.rows?.[0]?.steps?.find(v => v?.label == "Application Form")
                if (![0, 2]?.includes(applicationFormStep?.status)) {
                    setIsPrimeApplicationSubmitted(true)
                }
            }
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        if (application_id) {
            checkIfUpgradeToPrime();
        }
    }, [application_id]);

    return (
        <div style={{ margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
            <div className={'form-content '}>

                <div className='d-flex flex-column  justify-content-center  align-items-center  '>


                    <div className='w-100 d-flex flex-column justify-content-center align-items-center  position-relative'>
                        {<img
                            src={Logo}
                            alt="fv-bank"
                            style={{ marginBottom: "10px" }}
                            className="logo logo-dark header-logo"
                        />}
                        <div className='heading'>{t(isPrimeApplicationSubmitted ? "Business Prime" : type)}</div>
                        <div className='language-picker-form'><LanguagePicker /></div>
                    </div>

                    <JourneySteps
                        isKYCComplete={type === "Business Plus" ? allKYCComplete : true}
                        type={type == "Business Plus" ? "horizontal" : "vertical"}
                    />

                    {type == "Business Plus" ? <BusinessPlusKYCStatus onAllKYCComplete={setAllKYCComplete}  applicationId={application_id} /> : null}

                    {type == "Business Plus" && !isPrimeApplicationSubmitted ? <RedirectToBusinessPrime isAppliedForPrime={isAppliedForPrime} /> : null}
                </div>
            </div>
        </div>
    )
}

export default ThankYou

