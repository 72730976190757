import { ThreeBounce } from 'better-react-spinkit';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FaEnvelope, FaFilePdf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { DialogContext } from '../../../../store/context/DialogContext';
import { useTranslation } from '../../../../store/context/TranslationContext';
import { doPOST } from '../../../../util/HttpUtil';
import "./styles.scss";

const RedirectToBusinessPrime = ({ isAppliedForPrime  }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { showError } = useContext(DialogContext);

    const handleUpgradeToPrime = async () => {
        setLoading(true)
        try {
            const response = await doPOST("/api/business-prime/create");
            if (response.status === 200) {
                navigate(`/business-prime/${response?.data?._id}`, { replace: true });
            }
        } catch (error) {
            showError("Error creating/retrieving BP application:", error);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    return (
        <div className="fv_prime_upgrade_container w-100 ">
            <Row className="justify-content-center text-center mb-2">
                <Col md={8}>
                    {isAppliedForPrime ? (
                        <>
                            <h2 className="fv_prime_upgrade_main_title mb-3">{t("You've already shown your interest in Business Prime!")}</h2>
                            <p className="fv_prime_upgrade_lead ">
                                {t("Review or update your existing Prime application by clicking the button below.")}
                            </p>

                            <Row className="justify-content-center mb-0 mt-4 ">
                                <Col md={8} className="text-center">
                                    {loading ? <Button style={{ width: "200px" }} variant="primary" size="lg" >
                                        <ThreeBounce size={10} color="#FFFFFF" />
                                    </Button> :
                                        <Button
                                            className="fv_prime_upgrade_cta"
                                            variant="primary"
                                            size="lg"
                                            onClick={handleUpgradeToPrime}>
                            
                                            {t("Update Your Prime Application")}
                                       
                                        </Button>}
                                </Col>
                            </Row>

                        </>
                    ) : <></>}
                </Col>
            </Row>

            {!isAppliedForPrime && (
                <div className=" ">
                    <Row className="justify-content-center text-center mb-5">
                        <Col lg={12} className="fv_prime_upgrade_heading_section">
                            <div className="fv_prime_upgrade_main_title mb-2">
                                {t("Interested in Business Prime?")}
                            </div>
                            <p className="fv_prime_upgrade_lead">
                                {t("If you're exploring Business Prime, please continue to the next section.")}
                            </p>
                        </Col>
                    </Row>

                    
                    <Row className="justify-content-center mb-5">
                        <Col lg={10} className="fv_prime_upgrade_card ">
                            <div className="fv_prime_upgrade_regulated_section">
                                <div className="text-center fv_prime_upgrade_main_title mb-4">
                                    {t("Designed for Regulated Financial Businesses")}
                                </div>
                                <p className="fv_prime_upgrade_lead text-center">
                                    {t("This section is specifically tailored for regulated Money Service Businesses, Fintech firms, Virtual Asset Service Providers (VASPs) and Digital Asset Exchanges.")}
                                </p>
                                
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center mb-5">
                        <Col lg={10} className="fv_prime_upgrade_info_section  text-center">
                            <div className="fv_prime_upgrade_main_title mb-3">{t("Learn More")}</div>
                            <p className="fv_prime_upgrade_lead mb-4">
                                {t("For further details, review the brochures:")}
                            </p>
                            <div className="d-flex flex-column flex-md-row justify-content-center gap-3">
                                <Button
                                    className="fv_prime_upgrade_brochure_link prime"
                                    href="https://www.fvbank.us/resources/FV-Bank-Business-Plus-Brochure.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFilePdf className="me-2" />
                                    {t("Business Plus Brochure")}
                                </Button>
                                <Button
                                    className="fv_prime_upgrade_brochure_link prime"
                                    href="https://www.fvbank.us/resources/fvbank-Business-Prime.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FaFilePdf className="me-2" />
                                    {t("Business Prime Brochure")}
                                </Button>
                            </div>
                        </Col>
                    </Row>




                    <Row className="justify-content-center mb-0">
                        <Col lg={10} className=" text-center">
                            <h4 className="fv_prime_upgrade_main_title mb-3">{t("Contact")}</h4>
                            <p className="fv_prime_upgrade_lead  d-flex flex-column flex-md-row align-items-center justify-content-center">
                                {t("Connect with your sales representative or")}{' '}
                                <a
                                    href="mailto:sales@fvbank.us"
                                    className="fv_prime_upgrade_email_link"
                                >
                                    <FaEnvelope className="ms-2 me-1" />
                                    sales@fvbank.us
                                </a>
                            </p>
                        </Col>
                    </Row>


                    <Row className="justify-content-center mt-5 mb-2 ">
                        <Col md={8} className="text-center">
                            {loading ? <Button style={{ width: "200px" }} variant="primary" size="lg" >
                                <ThreeBounce size={10} color="#FFFFFF" />
                            </Button> :
                                <Button
                                    className="fv_prime_upgrade_cta"
                                    variant="primary"
                                    size="lg"
                                    onClick={handleUpgradeToPrime}>
                                    {t("Upgrade to Prime")}
                                </Button>}
                        </Col>
                    </Row>

                </div>
            )}

            
        </div>
    );
};

export default RedirectToBusinessPrime;
