import React, { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Logo from '../../../assets/images/Logo.svg';
import './OndatoSuccess.scss';
import { handleUtmKycDone } from '../../../util/utm';

const OndatoSuccess = () => {

    useEffect(() => {
        handleUtmKycDone({});
    }, []);

    return (
        <div className="fv_ondato_success-container">
            <div className="fv_ondato_success-content">
                <div className="fv_ondato_success-header">
                    <img src={Logo} alt="Logo" className="fv_ondato_success-logo mb-5" />
                    <FaCheckCircle className="fv_ondato_success-icon" />
                    <h2 className="fv_ondato_success-title">Verification Details Submitted!</h2>
                </div>
                <p className="fv_ondato_success-message">
                    Your identity verification details have been submitted. Please log in again to continue.
                </p>
            </div>
        </div>
    );
};

export default OndatoSuccess;
