import pincodeService from "./PincodeService";

export function getPincodeConfig(countryCode) {
    if (!countryCode) return null;
    const records = pincodeService.records;
    return records[countryCode.toUpperCase()] || null;
}

export function validatePincodeByCountry(countryCode, pincode) {
    const config = getPincodeConfig(countryCode);
    if (!config || !config.regexForPostalCode) {
        return true;
    }
    const pattern = config.regexForPostalCode.replace(/\\\\/g, '\\');
    const regex = new RegExp(`^${pattern}$`);
    return regex.test(pincode);
}

export function getDefaultValue(countryCode) {
    const config = getPincodeConfig(countryCode);
    if (config && config.defaultValue) {
        return config.defaultValue;
    }

    return null
}
