import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { validatePincodeByCountry } from './helper';
import pincodeService from './PincodeService';
import "./styles.css"; // Ensure your CSS file is in place

function PincodeField(props) {
    const {
        countryCode,
        value: initialValue,
        onChange,
        label = "Pincode",
        isDisabled,
        required,
        className,
        showErr,
        ...rest
    } = props;

    const [value, setValue] = useState(initialValue || "");
    const [error, setError] = useState(null);
    const [hasError, setHasError] = useState(false);

    // Update the field value if the initial value changes.
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    // Trigger fetching of pincode data (if not already loaded).
    useEffect(() => {
        if (Object.keys(pincodeService.records).length === 0) {
            pincodeService.fetch().catch(console.error);
        }
    }, []);

    // Handle change in the input.
    const handleChange = (newValue) => {
        setValue(newValue);
        if (onChange) {
            onChange(newValue);
        }
        // Reset any previous error.
        setError(null);
        setHasError(false);
    };

    // Validate the pincode.
    // If shouldNotEmpty is true, then an empty value will produce an error.
    const validatePincode = (shouldNotEmpty) => {
        if (shouldNotEmpty) {
            if (!value) {
                setError("Please enter a valid pincode");
                setHasError(true);
                return;
            }
        } else {
            if (!value) return;
        }
        const isValid = validatePincodeByCountry(countryCode, value);
        if (!isValid) {
            setError("Please enter a valid pincode");
            setHasError(true);
        } else {
            setError(null);
            setHasError(false);
        }
    };

    useEffect(() => {
        validatePincode();
    }, [countryCode, value]);

    useEffect(() => {
        if (showErr) {
            validatePincode(true);
        }
    }, [showErr]);

    return (
        <div className={`${className ? className : ''}`}>
            <div className="inputfield__container">
                <div className="inputfield__innercontainer_outline">
                    <div className="inputfield ps-0" style={{ marginBottom: '5px' }}>
                        <input
                            className={`inputfield__input ${hasError ? 'error' : ''}`}
                            value={value}
                            onChange={(e) => handleChange(e.target.value)}
                            onBlur={validatePincode}
                            disabled={isDisabled}
                            {...rest}
                            style={{
                                height: "50px",
                                borderRadius: "15px",
                                paddingLeft: "15px",
                                border: "none",
                                fontSize: "14px",
                                outline: "none",
                                backgroundColor: "#F1FAFC",
                                width: "100%"
                            }}
                        />
                    </div>
                </div>
            </div>
            <em className="inputfield__desc d-block">
                {"E.g. 110001."}
            </em>
            {error && (hasError || showErr) && (
                <em className="inputfield__error">
                    {error}
                </em>
            )}
        </div>
    );
}

export default observer(PincodeField);
