import { makeAutoObservable } from "mobx";
import { doGET } from "../../../../util/HttpUtil";

class PincodeService {
    // Now records is an object mapping country codes to their config
    records = {};
    loading = false;
    _fetchPromise = null;

    constructor() {
        makeAutoObservable(this);
    }

    fetch = async () => {
        // Return cached data if available.
        if (Object.keys(this.records).length > 0) {
            return this.records;
        }

        // If a fetch is already in progress, return the same promise.
        if (this._fetchPromise) {
            return this._fetchPromise;
        }

        this.loading = true;
        this._fetchPromise = doGET("/api/o/country/validation/json")
            .then(response => {
                if (response?.status === 200) {
                    // Save the received object
                    this.records = response.data;
                }
                this.loading = false;
                this._fetchPromise = null;
                return this.records;
            })
            .catch(error => {
                console.error("Error fetching pincode data:", error);
                this.loading = false;
                this._fetchPromise = null;
                throw error;
            });

        return this._fetchPromise;
    };
}

const pincodeService = new PincodeService();
export default pincodeService;
