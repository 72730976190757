import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Modal, ModalBody, Button } from "reactstrap";
import { DialogContext } from "../../store/context/DialogContext";
import { GrDocument } from "react-icons/gr";
import { FaSpinner } from "react-icons/fa";

/**
 * Attempt to parse the filename from 'Content-Disposition' response header.
 * Content-Disposition typically looks like: attachment; filename="myFileName.docx"
 */
function getFilenameFromContentDisposition(headerValue) {
    if (!headerValue) return null;
    const match = headerValue.match(/filename="?([^"]+)"?/);
    return match && match[1] ? match[1] : null;
}

/** Fallback: parse a filename from a URL (e.g. last path segment). */
function getFilenameFromUrl(url) {
    try {
        const decodedURL = decodeURIComponent(url);
        return decodedURL.substring(decodedURL.lastIndexOf("/") + 1) || "file";
    } catch {
        // If decoding fails or url is missing
        return "file";
    }
}

/**
 * Shows an icon for doc/ppt/etc. and allows the user to download the file with the correct name.
 */
const OfficeLabel = ({ src, style, ...restProps }) => {
    const { showError } = useContext(DialogContext);
    const [modal, setModal] = useState(false);
    const [fileBlobUrl, setFileBlobUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadName, setDownloadName] = useState("file"); // the name for the downloaded file

    const toggleModal = () => setModal((prev) => !prev);

    const fetchFile = async (url) => {
        setLoading(true);
        try {
            const response = await axios({
                url,
                method: "GET",
                responseType: "blob",
                headers: {
                    Authorization: localStorage.getItem("BearerToken"),
                },
                withCredentials: true,
            });

            // 1) Create a Blob URL for the file
            const blobUrl = window.URL.createObjectURL(response.data);
            setFileBlobUrl(blobUrl);

            // 2) Try to extract a filename from headers
            const disposition = response.headers["content-disposition"];
            let filename = getFilenameFromContentDisposition(disposition);

            // 3) Fallback to the last path segment if no header-based filename
            if (!filename) {
                filename = getFilenameFromUrl(url);
            }

            setDownloadName(filename);
        } catch (error) {
            console.error("Error fetching file:", error);
            showError("Failed to load file");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Only fetch the file if the user actually opens the modal and the src is from /api
        if (modal && src?.startsWith("/api")) {
            fetchFile(src);
        }
    }, [modal, src]);

    if (loading)
        return (
            <div
                style={{ width: "60px", height: "60px", cursor: "pointer" }}
                className="fv_ondato_rule d-flex justify-content-center align-items-center"
            >
                <FaSpinner size={14} className="spinner" />
            </div>
        );

    return (
        <div style={style} {...restProps}>
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ width: "60px", height: "60px", cursor: "pointer" }}
                onClick={() => src && toggleModal()}
            >
                <GrDocument size={40} />
            </div>

            {/* Modal for doc/ppt/etc. with a simple "Download" button */}
            <Modal isOpen={modal} toggle={toggleModal} centered>
                <ModalBody className="text-center">
                    <h5>Preview not available</h5>
                    <p>You can download and open this file locally.</p>
                    {fileBlobUrl ? (
                        <Button
                            color="primary"
                            tag="a"
                            href={fileBlobUrl}
                            download={downloadName} // sets the correct filename with extension
                        >
                            Download 
                        </Button>
                    ) : (
                        <Button color="primary" disabled>
                            Preparing download...
                        </Button>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default OfficeLabel;
