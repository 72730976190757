import React, { useState } from 'react';
import Logo from "../../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from './components/ButtonGroup';
import "./styles.scss";
import BusinessContent from './BusinessContent';
import { Button } from 'react-bootstrap';

const ApplyBusinessLiteContent = () => {
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const queryParams = urlObject.search;
    const [sz, setSz] = useState(20);


    return (
        <div>
            <div className="fv_business_intro_container container py-4">
                {/* Desktop Layout (md and up) */}
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title mt-3 mb-3">Business Plus</div>
                </div>

                <BusinessContent />

                <div className='mt-2'>

                    <Button
                        style={{
                            backgroundColor: '#1678AE',
                            height: "45px",
                            padding: "2px 4px 2px 4px",
                            fontSize: "20px",
                            width: "200px",
                            borderRadius: "10px"
                        }}
                        onClick={() => { navigate(`/business-plus/login${queryParams}`) }}
                    >
                        {("Business Plus")}
                    </Button>


                </div>
            </div>

        </div>

    );
};

export default ApplyBusinessLiteContent;
