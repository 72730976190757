import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Col, Row } from "reactstrap";
import { formatBytes } from "..";
import { Image } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { useTranslation } from "../../store/context/TranslationContext";
import { doDELETE } from "../../util/HttpUtil";
import PDFLabel from "../Image/PDFLabel";
import { isDoc, isImage, isPdf, isPpt } from "./DocViewer";
import OfficeLabel from "../Image/OfficeLabel";

function getFilenameFromPath(path) {
  const parts = path?.split('/');
  return parts[parts.length - 1];
}


const truncateFilename = (filename) => {
  if (filename.length > 20) {
    const start = _.slice(filename, 0, 5).join('');
    const end = _.slice(filename, -7).join('');
    return `${start}.....${end}`;
  }
  return filename;
};

const FILE_EXTENSIONS = {
  image: [".png", ".jpeg", ".jpg"],
  pdf: [".pdf"],
  doc: [".doc", ".docx", ".txt"],
  ppt: [".ppt", ".pptx"],
};

const getDropzoneAccept = (supportedFiles) => {
  const accept = {};

  if (supportedFiles.includes("image")) {
    accept["image/*"] = FILE_EXTENSIONS.image;
  }
  if (supportedFiles.includes("pdf")) {
    accept["application/pdf"] = FILE_EXTENSIONS.pdf;
  }
  if (supportedFiles.includes("doc")) {
    accept["application/msword"] = [".doc"];
    accept["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] = [".docx"];
    accept["text/plain"] = [".txt"];
  }
  if (supportedFiles.includes("ppt")) {
    accept["application/vnd.ms-powerpoint"] = [".ppt"];
    accept["application/vnd.openxmlformats-officedocument.presentationml.presentation"] = [".pptx"];
  }

  return accept;
};

const getSupportedFilesString = (supportedFiles) => {
  const extList = supportedFiles
    .flatMap(type => FILE_EXTENSIONS[type] || [])
    .join(", ");
  return extList || "";
};


const DropZone = ({ asLabel, inline, value, onChange = () => { }, fileSizeText, fileSize, onDeleteAttachment = () => { }, onDelete, module, field, id,
  supportedFiles = ["image", "pdf", "doc", "ppt"],
  single = false

}) => {
  const [selectedFiles, setSelectedFiles] = useState(value ? value : []);
  const { showConfirm, showMessage, showError } = useContext(DialogContext)
  const { t } = useTranslation();

  function handleAcceptedFiles(files) {
    const maxFileSize = 104857600; // 100 MB in bytes
    const supportedFileExtensions = supportedFiles.reduce((acc, fileType) => {
      if (fileType === "image") {
        acc.push(...FILE_EXTENSIONS.image);
      } else if (fileType === "pdf") {
        acc.push(...FILE_EXTENSIONS.pdf);
      } else if (fileType === "doc") {
        acc.push(...FILE_EXTENSIONS.doc);
      } else if (fileType === "ppt") {
        acc.push(...FILE_EXTENSIONS.ppt);
      }
      return acc;
    }, []);

    const invalidFiles = files.filter(file => {
      if (file != undefined) {
        const fileExtension = file?.name?.slice(file?.name?.lastIndexOf("."))?.toLowerCase();
        return !supportedFileExtensions.includes(fileExtension) || file.size > maxFileSize;;
      }
      return true
    });

    if (invalidFiles.length > 0 || !files?.length) {
      const exts = getSupportedFilesString(supportedFiles);
      showError(
        `Unsupported file format or file too large. Please upload only ${exts} files (max 100 MB).`
      );
      return;
    }

    files.map((file) =>
      Object.assign(file, {
        key: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    if (single) {
      setSelectedFiles([files[0]]);
      onChange([files[0]]);
    } else {
      setSelectedFiles([...selectedFiles, ...files]);
      onChange([...selectedFiles, ...files]);
    }
  }

  useEffect(() => {
    setSelectedFiles(value ? value : []);
  }, [value]);

  const handleDelete = async () => {
    if (
      await showConfirm({
        title: t("Do you want to delete file?"),
        description: t("This is an unrecoverable operation."),
      })
    ) {
      try {
        const data = {
          _id: id,
          field: field,
          module: module,
        }
        const response = await doDELETE("/api/file/delete/single", data);
        if (response.status === 200) {
          showMessage("Deleted", "Deleted");
          onDelete()
        }
      } catch (e) {
        showError(e);
      }
    }
  };



  return (
    <Row style={{ width: "100%", }}>

      <div className={`${inline ? " d-flex flex-wrap p-0 " : ""}`}>
        {(!single || selectedFiles && selectedFiles.length == 0 ||
          !selectedFiles[0]?.path) && (selectedFiles)?.filter(v => v != undefined).map((f, i) => {
            if (f)
              return (
                <div
                  className="mt-1 mb-0 shadow-none px-0 dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div
                    className={`${inline ? "pb-2 px-2" : "p-2"}`}
                  >
                    <div style={{ position: "relative" }} className="d-flex align-items-center justify-content-between">

                      <FileUploadRowItem hideIndexes file={f} index={i} />

                      {!asLabel && <div
                        style={{ position: "absolute", right: "-8px", top: "-8px" }}
                        className=" d-flex align-items-end justify-content-end cursor-pointer"
                        onClick={(index) => {
                          if (f?.size) {
                            const updatedSelectedFiles = [...selectedFiles];
                            updatedSelectedFiles.splice(i, 1);
                            setSelectedFiles(updatedSelectedFiles);
                          } else if (typeof f === 'string') {
                            handleDelete();
                          } else if (f?.key?.slice(1, 13) === "api/o/assets") {
                            onDeleteAttachment(i).then((deleted) => {
                              if (deleted) {
                                const updatedSelectedFiles = [...selectedFiles];
                                updatedSelectedFiles.splice(index, 1);
                                setSelectedFiles(updatedSelectedFiles);
                              }
                            });
                          }
                        }}
                      >
                        <i
                          style={{
                            fontSize: "14px",
                            border: "2px solid red",
                            borderRadius: "20px",
                            color: "red",
                          }}
                          className="dripicons-cross text-red"
                        />
                      </div>}
                    </div>
                  </div>
                </div>
              ); else return null
          })}
      </div>

      {asLabel || (single ? (selectedFiles)?.filter(v => v != undefined).length > 0 : false) ? null :
        <Dropzone
          onDrop={(acceptedFiles) => {
            handleAcceptedFiles(acceptedFiles);
          }}
          accept={getDropzoneAccept(supportedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone needsclick ms-2 mt-2">
              <div className="dz-message " {...getRootProps()}>
                <input {...getInputProps()} />
                {single && selectedFiles && selectedFiles.length == 1 && selectedFiles[0] && selectedFiles[0]?.path ?
                  <div className="d-flex align-items-center justify-content-between">
                    <FileUploadRowItem file={selectedFiles[0]} index={0} />
                    <div className=" d-flex align-items-end justify-content-end">
                      <h4>
                        {/* <img src={upload} alt="upload" height="50" /> */}
                        <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t("Drag & Drop your files here or Choose Files.")}</div>
                        <div className="font-size-14 mt-2" style={{ color: "#AAA" }}>
                          {t(
                            `Supported files: ${getSupportedFilesString(
                              supportedFiles
                            )} | Max size: ${fileSizeText || "100 MB"}`
                          )}                        </div>
                      </h4>
                    </div>
                  </div>
                  :
                  <h4>
                    {/* <img src={upload} alt="upload" height="50" /> */}
                    <div className=" font-size-14 mt-2" style={{ color: "#AAA" }}>{t("Drag & Drop your files here or Choose Files.")}</div>
                    <div className="font-size-14 mt-2" style={{ color: "#AAA" }}>
                      {t(
                        `Supported files: ${getSupportedFilesString(
                          supportedFiles
                        )} | Max size: ${fileSizeText || "100 MB"}`
                      )}
                    </div>
                  </h4>
                }
              </div>
            </div>
          )
          }
        </Dropzone >}

    </Row >
  );
};

const FileUploadRowItem = ({ hideIndexes, file, index }) => {
  const fileSrc = file?.key ?? file;

  let Content;

  if (isPdf(file)) {
    Content = <PDFLabel src={fileSrc} />;
  } else if (isImage(file)) {
    Content = (
      <Image
        style={{ width: "60px", height: "60px" }}
        width="60px"
        height="60px"
        src={fileSrc}
      />
    );
  } else if (isDoc(file) || isPpt(file)) {
    Content = <OfficeLabel src={fileSrc} />;
  } else {
    Content = <OfficeLabel src={fileSrc} />;
  }


  return (
    <div style={{ border: "1px solid #f0f0f0" }} className="d-flex pe-3 flex-1 w-100 align-items-center">

      {!hideIndexes && <Col className="col-auto">
        <div className="h3 fw-bold me-3">{index + 1}.</div>
      </Col>}

      <Col className="col-auto">{Content}</Col>

      <Col className="ms-2 col-auto">

        <h3 className="text-muted font-weight-bold">
          {truncateFilename((file?.name) ?? getFilenameFromPath(file?.key ?? file))}
        </h3>
        <p className="mb-0">
          <h4>
            {file.formattedSize}
          </h4>
        </p>
      </Col>
    </div>
  )
}

export default observer(DropZone); 
