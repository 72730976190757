import React from 'react';
import { InputField } from '../../../../../components';
import SignPad from "../../components/IndividualLite/SignPad";
import { InputLabel } from '../../components/IndividualLite/UserDetailsForm';

const Agreements = ({ handleValidation, asLabel, data, handleChange, hasError, validationkeyValue, }) => {


    return (
        <div className='d-flex row justify-content-start mb-4'>

            <InputLabel label="Signature of Applicant" required />
            <div className='mb-2'></div>
            <SignPad
                asLabel={asLabel}
                value={data?.signature}
                onChange={(sign) => handleChange('signature', sign)}
            />

            <InputLabel required label={"Name of Applicant"} labelStyle={{ fontSize: 15 }} />

            <div className='row'>

                <InputField
                    className="col-sm-4"
                    placeholder="First"
                    value={data?.signerFirstName}
                    onChange={(v) => handleChange(`signerFirstName`, v)}
                    error={handleValidation("signerFirstName", data?.signerFirstName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />

                <InputField
                    className="col-sm-4"
                    placeholder="Middle"
                    value={data?.signerMiddleName}
                    onChange={(v) => handleChange(`signerMiddleName`, v)}
                    error={handleValidation("signerMiddleName", data?.signerMiddleName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />

                <InputField
                    className="col-sm-4"
                    placeholder="Last"
                    value={data?.signerLastName}
                    onChange={(v) => handleChange(`signerLastName`, v)}
                    error={handleValidation("signerLastName", data?.signerLastName)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />

            </div>

        </div >
    );
}

export default Agreements;