import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './style.css';
import { useTranslation } from '../../../../../store/context/TranslationContext';

const JourneySteps = ({ isKYCComplete = false, type = "horizontal", formType }) => {
    const { t } = useTranslation();

    return (
        <div className="fv_application_journey_container">
            <div className={`fv_application_journey_steps ${type}`}>
                <div className={`fv_application_journey_step ${type} fv_application_journey_step--completed`}>
                    <div className={`fv_application_journey_step_circle ${type}`}>
                        <FaCheck className="fv_application_journey_step_icon" />
                    </div>
                    <div className="fv_application_journey_content">
                        <h4 className="fv_application_journey_title">{t("Application Submission")}</h4>
                        <p className="fv_application_journey_desc">{t("Thank you for submitting the application.")}</p>
                    </div>
                </div>

               
                <div className={`fv_application_journey_step ${type} ${isKYCComplete ?
                    'fv_application_journey_step--completed' :
                    'fv_application_journey_step--current'
                    }`}>
                    <div className={`fv_application_journey_step_circle ${type}`}>
                        {isKYCComplete ? (
                            <FaCheck className="fv_application_journey_step_icon" />
                        ) : (
                            <span className="fv_application_journey_step_number">2</span>
                        )}
                    </div>
                    <div className="fv_application_journey_content">
                        <h4 className="fv_application_journey_title">{t("KYC Completion")}</h4>
                        <p className="fv_application_journey_desc">
                            {isKYCComplete
                                ? t(formType == "Business Plus"  ? "All operators have completed KYC verification"  : "Thankyou for completing your KYC.")
                                : t("Please follow up with all users to ensure KYCs are complete")}
                        </p>
                    </div>
                </div>

                <div className={`fv_application_journey_step ${isKYCComplete ? "fv_application_journey_step--current" : ""} ${type} `}>

                    <div className={`fv_application_journey_step_circle ${type}`}>
                        <span className="fv_application_journey_step_number">3</span>
                    </div>
                    <div className="fv_application_journey_content">
                        <h4 className="fv_application_journey_title">{t("Follow-up Responses")}</h4>
                        <p className="fv_application_journey_desc">{t("Agents will reach out if they need more information")}</p>
                    </div>
                </div>

                <div className={`fv_application_journey_step ${type} `}>

                    <div className={`fv_application_journey_step_circle ${type}`}>
                        <span className="fv_application_journey_step_number">4</span>
                    </div>
                    <div className="fv_application_journey_content">
                        <h4 className="fv_application_journey_title">{t("Receive Credentials")}</h4>
                        <p className="fv_application_journey_desc">
                            {t("You will receive credentials on separate emails for the")}
                            <a href="https://portal.fvbank.us" className="fv_application_journey_link">
                                {" "}  {t("portal")}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JourneySteps

