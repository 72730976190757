import React, { useState } from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import { InputField } from '../../../../../components';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import PDFViewer from '../../../../../components/Image/PDF';

export const QuestionRadioGroupMultiple = ({ isDisabled, field, question, data, handleChange, hasError, style, options, required, showErr, error }) => (
    <div>
        <FormGroup>
            <Form.Label className='mt-3' style={{ minWidth: "120px", padding: "0px", fontSize: 16, fontWeight: "500", ...style }}>
                {question}
                {!!required ? <font color="red">*</font> : ""}
            </Form.Label>
            <div className='row'>
                {
                    options.map((e, i) => (
                        <div key={i} className='col-12 col-md-6 mb-2'>
                            <Form.Check
                                id={e.value}
                                type="radio"
                                disabled={isDisabled}
                                label={e.label}
                                name={field}
                                value={e.value}
                                checked={_.get(data, field) === e.value}
                                onChange={() => handleChange(field, e.value)}
                                className='me-2'
                            />
                        </div>
                    ))
                }
            </div>
            {data?.[field] === undefined && (showErr || hasError) && (
                <div className="ps-0 ms-0 w-100 error__message">{error}</div>
            )}
        </FormGroup>
    </div>
);

const Page6 = ({ form_id, asLabel, data, handleChange, hasError, handleValidation, validationkeyValue }) => {
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    return (
        <div className='mb-3'>
            <QuestionRadioGroupMultiple
                field={"hearAbtUs"}
                data={data}
                handleChange={handleChange}
                hasError={hasError}
                isDisabled={asLabel}
                style={{ fontSize: 15 }}
                options={validationkeyValue["hearAbtUs"]?.options}
                question={validationkeyValue["hearAbtUs"]?.label}
                error={handleValidation(`hearAbtUs`, data?.hearAbtUs)}
                showErr={hasError}
                required={validationkeyValue["hearAbtUs"]?.required}
            />
            {/* 
            <InputField
                className="my-4"
                label="Please specify"
                // required
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.hearAbtUsOther}
                onChange={(v) => { handleChange("hearAbtUs", v) }}
                error={handleValidation("hearAbtUs", data?.hearAbtUs)}
                showErr={hasError}
                type="text"
            /> */}

            <InputField
                className="my-4"
                label="Indicate the name of the conference/event/trade show"
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.showName}
                onChange={(v) => { handleChange("showName", v) }}
                error={handleValidation("showName", data?.showName)}
                showErr={hasError}
                type="text"
                isDisabled={asLabel}
                required={validationkeyValue["showName"]?.required}

            />

            <div className='d-flex mt-4  align-items-center'>
                <Input
                    type="checkbox"
                    checked={data?.tnc}
                    onChange={(e) => {
                        if (!asLabel) {
                            handleChange('tnc', e.target.checked);
                        }
                    }}
                />
                <span className='ps-2'>
                    I agree to the information provided in the
                    <span style={{ cursor: "pointer" }} onClick={() => { setModal(true) }} className='text-primary' > Certificate of Beneficial Owners</span>,
                    is accurate and complete to the best of my knowledge.
                </span>
            </div>

            <Modal size="xl" isOpen={modal} toggle={toggleModal} centered>
                <ModalHeader toggle={toggleModal}>Certificate of Beneficial Owners</ModalHeader>
                <ModalBody className="fv_doc_field_modal_body  ">
                    <PDFViewer
                        modalLoader
                        pdfStyle={{ height: "calc(80vh - 40px)" }}
                        src={`/api/application-doc/cert/beneficial-owners?form_id=${form_id}`} alt="Document" />
                </ModalBody>
            </Modal>

        </div>
    )
}

export default Page6;