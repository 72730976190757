import React, { useState, useRef, useEffect, useCallback } from 'react';
import "./style.css";

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const AddressAutocompleter = ({
  onSelect,
  value,
  onChange,
  error,
  showErr,
  desc,
  placeholder,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const containerRef = useRef(null);

  // Convert the selected place into an address object.
  const handlePlaceSelect = (place) => {
    if (!place || !place.address_components) {
      console.error("No address components available in the selected place:", place);
      return;
    }

    const addressComponents = {
      buildingNumber: '',
      street: '',
      pinCode: '',
      city: '',
      state: '',
      country: '',
    };

    let streetNumber = '';
    let streetRoute = '';

    place.address_components.forEach((component) => {
      const types = component.types;

      // Extract street number; if not found, fallback to 'premise'
      if (types.includes('street_number')) {
        streetNumber = component.long_name;
      } else if (!streetNumber && types.includes('premise')) {
        streetNumber = component.long_name;
      }

      // Extract the street name (route)
      if (types.includes('route')) {
        streetRoute = component.long_name;
      }

      // Postal code
      if (types.includes('postal_code')) {
        addressComponents.pinCode = component.long_name ? component.long_name.replace(/ /g, '') : '';
      }

      // City: check for 'locality' or possibly 'sublocality'
      if (types.includes('locality')) {
        addressComponents.city = component.long_name;
      } else if (!addressComponents.city && types.includes('administrative_area_level_2')) {
        addressComponents.city = component.long_name;
      } else if (!addressComponents.city && types.includes('sublocality_level_1')) {
        addressComponents.city = component.long_name;
      }

      // State
      if (types.includes("administrative_area_level_1")) {
        addressComponents.state = component.long_name;
      }

      // Country
      if (types.includes('country')) {
        addressComponents.country = component.short_name;
      }
    });

    addressComponents.buildingNumber = "APT# NA";
    addressComponents.street = (streetNumber ? streetNumber + " " : "") + streetRoute;

    onSelect(addressComponents);
    setSuggestions([]);
    setActiveSuggestionIndex(-1);
  };


  // Fetch predictions from the Google Places API.
  const fetchPredictions = (input) => {
    // If Google Maps API isn't loaded, simply exit (normal input behavior).
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.warn("Google Maps API is not available. Falling back to normal input.");
      setSuggestions([]);
      return;
    }

    try {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input, types: ['address'] }, (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions);
        } else {
          console.error("Error fetching place predictions:", status);
          setSuggestions([]);
        }
      });
    } catch (error) {
      console.error("Exception while fetching predictions:", error);
      setSuggestions([]);
    }
  };

  // Debounced version of fetchPredictions (300ms delay).
  const debouncedFetchPredictions = useCallback(
    debounce((input) => {
      fetchPredictions(input);
    }, 300),
    []
  );

  // Handle input changes and fetch suggestions if input is long enough.
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(inputValue);
    if (inputValue && inputValue.length > 2) {
      debouncedFetchPredictions(inputValue);
    } else {
      setSuggestions([]);
      setActiveSuggestionIndex(-1);
    }
  };

  // Close the suggestions dropdown if the user clicks outside.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputBlur = () => {
    setTimeout(() => {
      if (containerRef.current && !containerRef.current.contains(document.activeElement)) {
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
      }
    }, 150);
  };

  // Handle keyboard navigation.
  const handleKeyDown = (e) => {
    if (suggestions.length === 0) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveSuggestionIndex((prevIndex) => (prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveSuggestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      if (activeSuggestionIndex >= 0 && activeSuggestionIndex < suggestions.length) {
        const selectedPrediction = suggestions[activeSuggestionIndex];
        // Fetch full details for the selected suggestion.
        if (!window.google || !window.google.maps || !window.google.maps.places) {
          console.warn("Google Maps API is not available. Cannot fetch place details.");
          return;
        }
        try {
          const dummyDiv = document.createElement('div');
          const service = new window.google.maps.places.PlacesService(dummyDiv);
          service.getDetails({ placeId: selectedPrediction.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
              handlePlaceSelect(place);
            } else {
              console.error("Error fetching place details:", status);
            }
          });
        } catch (error) {
          console.error("Exception while fetching place details:", error);
        }
      }
    } else if (e.key === 'Escape') {
      setSuggestions([]);
      setActiveSuggestionIndex(-1);
    }
  };

  // Handle click on a suggestion item.
  const handleSuggestionClick = (prediction) => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.warn("Google Maps API is not available. Cannot fetch place details.");
      return;
    }
    try {
      const dummyDiv = document.createElement('div');
      const service = new window.google.maps.places.PlacesService(dummyDiv);
      service.getDetails({ placeId: prediction.place_id }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          handlePlaceSelect(place);
        } else {
          console.error("Error fetching place details on click:", status);
        }
      });
    } catch (error) {
      console.error("Exception while fetching place details on click:", error);
    }
  };

  return (
    <div className="inputfield__container" style={{ position: 'relative' }} ref={containerRef}>
      <div className="inputfield__innercontainer px-2">
        <input
          type="text"
          value={value}
          maxLength={40}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          placeholder={placeholder}
          className={`inputfield__input ${error && showErr ? 'is-invalid' : ''} mx-1`}
          autoComplete="off"
        />
      </div>

      {desc ? <em className="inputfield__desc d-block">
        {desc ? desc : ""}
      </em> : null}
      {error && showErr && <em className="inputfield__error">{error}</em>}

      {suggestions.length > 0 && (
        <div
          className="autocomplete-dropdown"
          style={{
            position: 'absolute',
            top: '46px',
            left: "10px",
            right: 0,
            zIndex: 1000,
            borderRadius: "0px 0px 10px 10px",
            marginRight: "10px",
            background: 'white',
            border: '1px solid #eee',
            borderTop: "0px",
            maxHeight: '200px',
            overflowY: 'auto',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.2)',
          }}
          role="listbox"
        >
          {suggestions.map((prediction, index) => (
            <div
              key={prediction.place_id}
              role="option"
              aria-selected={activeSuggestionIndex === index}
              className={`autocomplete-item ${activeSuggestionIndex === index ? 'active' : ''}`}
              style={{
                padding: '8px',
                cursor: 'pointer',
                borderTop: index === 0 ? 'none' : '1px solid #eee',
                backgroundColor: activeSuggestionIndex === index ? '#eee' : 'white'
              }}
              onMouseDown={(e) => e.preventDefault()} // Prevents the input from blurring when clicking
              onClick={() => handleSuggestionClick(prediction)}
              onMouseEnter={() => setActiveSuggestionIndex(index)}
            >
              {prediction.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
