import React, { useContext, useEffect, useState } from 'react';
import { FaRegUserCircle, FaDollarSign, FaMobileAlt, FaRegListAlt, FaTools } from 'react-icons/fa';
import '../IndividualLite.scss';
import Logo from "../../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from '../BusinessLiteForms/components/ButtonGroup';
import { DialogContext } from '../../../../store/context/DialogContext';
import BusinessContent from '../BusinessLiteForms/BusinessContent';
import { Button } from 'react-bootstrap';

const ApplyBusinessPrimeContent = () => {
    const { showError } = useContext(DialogContext)

    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const queryParams = urlObject.search;
    const [sz, setSz] = useState(18);
    const t = (v) => v

    useEffect(() => {
        if (window.screen.width < 550) setSz(30);

        if (localStorage?.getItem("error")) {
            showError(localStorage?.getItem("error"))
            localStorage.setItem("error", "")
        }
    }, [])
    return (
        <div className="">
            <div className="fv_business_intro_container container py-4">
                {/* Desktop Layout (md and up) */}
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title  mt-3 mb-3">Business Prime</div>
                </div>


                <BusinessContent />

                <div className='mt-2'>

                    <Button
                        style={{
                            backgroundColor: '#1678AE',
                            height: "45px",
                            padding: "2px 4px 2px 4px",
                            fontSize: "20px",
                            width: "200px",
                            borderRadius: "10px"
                        }}
                        onClick={() => { navigate(`/business-prime/login${queryParams}`) }}
                    >
                        {t("Business Prime")}
                    </Button>


                </div>
            </div>
        </div>
    );
};

export default ApplyBusinessPrimeContent;