import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/Logo.svg";
import { DialogContext } from '../../store/context/DialogContext';
import { doGET } from '../../util/HttpUtil';
import './UboDashboard.scss'; // Make sure to create and import the corresponding CSS file
import OndatoVerification from './components/ondato/OndatoVerification';
import PreUboDashboard from './PreUboDashboard';
import Skeleton from 'react-loading-skeleton';


const getStatusName = (status) => {
  switch (status) {
    case 0:
      return 'KYC Pending'; 
    case 1:
      return 'KYC Done';
    case 2:
      return 'KYC Pending'; 
    case 3:
      return 'KYC Done'; 
    case 4:
      return 'KYC Rejected'; 
    default:
      return 'KYC Pending'; 
  }
};


export const getColorForStatusViaString = (sts) => {
  switch (sts) {
    case "Draft":
      return "#6C757D";   
    case "Submitted":
      return "#6C757D";   
    case "Ask More Info":
      return "#ccb363";  
    case "Approved":
      return "#155724";  
    case "Rejected":
      return "#721c24";  
    case "KYC Rejected":
      return "#721c24"; 
    case "KYC Done":
      return "#155724"; 
    default:
      return "#6C757D";   
  }
};

export const getBackgroundColorForStatusViaString = (sts) => {
  switch (sts) {
    case "Draft":
      return "#F2F2F2";  
    case "Submitted":
      return "#F2F2F2";  
    case "Ask For Info":
      return "#F2F2F2";   
    case "Approved":
      return "#d4edda";   
    case "Rejected":
      return "#f8d7da";  
    case "KYC Rejected":
      return "#f8d7da";
    case "KYC Done":
      return "#d4edda"; 
    default:
      return "#F2F2F2";  
  }
};


// Skeleton Loader Components
const SkeletonLoader = () => (
  <div className="fv_ubo_skeleton w-100">
    <Skeleton height={30}/>
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
    <Skeleton height={30} />
  </div>
);

const UboDashboard = () => {
  const [step, setStep] = useState("main");  
  const [uboData, setUboData] = useState(null);
  const [ondatoData, setOndatoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showQRCode, setShowQRCode] = useState(false);

  const fetchUboData = async () => {
    const ubo_id = localStorage.getItem('ubo_id');
    if (!ubo_id) {
      localStorage.clear()
      return};

    try {
      const response = await doGET(`/api/ubo/detail?_id=${ubo_id}`);

      if (response.status === 200) {
        if (response?.data?.isAuthorizedUser) {
          setStep("verify")
        }
        setUboData(response.data);
        const ondatoResponse = await doGET(`/api/ondato/grid?form_id=${response.data?.application_id}&ubo_id=${ubo_id}&sortBy=seq&sortAsc=false&rows=-1`);
        setOndatoData(ondatoResponse?.data?.rows[0])
      }
    } catch (err) {
      setError(err);
      // showError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUboData();
  }, []);

  const handleVerifyKYC = () => {
    window.location.reload()
  };

  const handleShowQRCode = (show) => {
    setShowQRCode(show);
  };


  const handleVerificationSuccess = () => {
    setStep("main");
  };


  if (loading) {
    return (
      <div className="fv_ubo_container">
        <SkeletonLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="fv_ubo_container">
        <div className="fv_ubo_error">Error: {error.message}</div>
      </div>
    );
  }

  if (step === "verify") {
    return (
      <PreUboDashboard
        uboData={uboData}
        onSuccess={handleVerificationSuccess}  // Pass success callback to proceed to the main dashboard
      />
    );
  }


  return (
    <div className="fv_ubo_container">
      <img
        src={Logo}
        alt="fv-bank"
        height="50"
        style={{ marginBottom: "30px" }}
        className="logo logo-dark"
      />

      <h1 className="fv_ubo_title">Welcome, {uboData?.firstName} {uboData?.lastName}</h1>
      <p className="fv_ubo_subtitle">Thank you for applying to FV Bank</p>
      {uboData && (
        <div className="fv_ubo_details">
          {!showQRCode && (
            <>

              <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Status:</span>
                <span className="fv_ubo_value">
                  <div
                    className='px-3'
                    style={{
                      borderRadius: "3px ",
                      fontSize: "12px",

                      border: `1px solid ${getColorForStatusViaString(getStatusName(ondatoData?.flowStatus))}`,
                      color: `${getColorForStatusViaString(getStatusName(ondatoData?.flowStatus))}`,
                      backgroundColor: `${getBackgroundColorForStatusViaString(getStatusName(ondatoData?.flowStatus))}`,
                    }}
                  >
                    {getStatusName(ondatoData?.flowStatus)}
                  </div>
                </span>
              </div>

              <div className="fv_ubo_detail">
                <span className="fv_ubo_label">First Name:</span>
                <span className="fv_ubo_value">{uboData.firstName}</span>
              </div>
              <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Last Name:</span>
                <span className="fv_ubo_value">{uboData.lastName}</span>
              </div>
              <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Email:</span>
                <span className="fv_ubo_value">{uboData.email}</span>
              </div>
              {uboData.phone ? <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Phone:</span>
                <span className="fv_ubo_value">{uboData.phone}</span>
              </div> : null}
              <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Address:</span>
                <span className="fv_ubo_value">
                  {uboData.address.addressLine ?? uboData.address.buildingNumber}, {uboData.address.addressLine2 ?? uboData.address.street}, {uboData.address.city}, {uboData.address.state}, {uboData.address.country} - {uboData.address.pinCode}
                </span>
              </div>
              {uboData.role ? <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Role:</span>
                <span className="fv_ubo_value">{uboData.role}</span>
              </div> : null}
              {uboData.accessType ? <div className="fv_ubo_detail">
                <span className="fv_ubo_label">Access Type:</span>
                <span className="fv_ubo_value">{uboData.accessType}</span>
              </div> : null}




            </>
          )}
          <div className={`w-100 justify-content-${showQRCode ? "center" : "end"} d-flex`}>
            {
              [1,3,4,5]?.includes(ondatoData?.flowStatus)? <div> </div> :
                <OndatoVerification
                  formId={uboData?.application_id}
                  uboId={uboData?._id}
                  onComplete={handleVerifyKYC}
                  onShowQRCode={handleShowQRCode}
                />
            }
          </div>
        </div>
      )}

    </div>
  );
};

export default UboDashboard;
