export function getUtmParams() {
    const urlParams = new URLSearchParams(window.location.search);

    const utmData = {
        utm_source: urlParams.get('utm_source') || '',
        utm_medium: urlParams.get('utm_medium') || '',
        utm_campaign: urlParams.get('utm_campaign') || '',
        utm_content: urlParams.get('utm_content') || '',
        utm_term: urlParams.get('utm_term') || '',
        gclid: urlParams.get('gclid') || '',
        fbclid: urlParams.get('fbclid') || '',
    };
    return utmData;
}

export const sendGtagEvent = (eventName, eventParams) => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
        window.gtag('event', eventName, eventParams);
    } else {
        console.warn('== gtag is not available ==');
    }
};


const buildEventPayload = ({ utm, formType }) => {
    const storedUtmString = localStorage.getItem('utmParams');
    const utmLocal =  storedUtmString ? JSON.parse(storedUtmString) : {};

    const utmPayload = {
        ...(formType && {formType}),
        ...(utm?.utm_source && { utm_source: utm.utm_source }),
        ...(utm?.utm_medium && { utm_medium: utm.utm_medium }),
        ...(utm?.utm_campaign && { utm_campaign: utm.utm_campaign }),
        ...(utm?.utm_term && { utm_term: utm.utm_term }),
        ...(utm?.utm_content && { utm_content: utm.utm_content }),
        ...(utmLocal?.gclid && { gclid: utmLocal.gclid }),
        ...(utmLocal?.fbclid && { fbclid: utmLocal.fbclid }),
    }
    return utmPayload
}


export const handleUtmInterested = ({ utmPayload, formType }) => {
    const storedUtmString = localStorage.getItem('utmParams');
    const utm = Object?.keys(utmPayload ?? {})?.length ? utmPayload : storedUtmString ? JSON.parse(storedUtmString) : {};
    sendGtagEvent('INTERESTED', buildEventPayload({ utm, formType }));
};

export const handleUtmLoginSuccess = ({ utmPayload, formType }) => {
    const storedUtmString = localStorage.getItem('utmParams');
    const utm = Object?.keys(utmPayload ?? {})?.length ? utmPayload : storedUtmString ? JSON.parse(storedUtmString) : {};
    sendGtagEvent('LOGGED_IN', buildEventPayload({ utm, formType }));
};

export const handleUtmFormSubmit = ({ utmPayload, formType }) => {
    const storedUtmString = localStorage.getItem('utmParams');
    const utm = Object?.keys(utmPayload ?? {})?.length ? utmPayload : storedUtmString ? JSON.parse(storedUtmString) : {};
    sendGtagEvent('FORM_FILLED', buildEventPayload({ utm, formType }));
};

export const handleUtmKycDone = ({ utmPayload, formType }) => {
    const storedUtmString = localStorage.getItem('utmParams');
    const utm = Object?.keys(utmPayload ?? {})?.length ? utmPayload : storedUtmString ? JSON.parse(storedUtmString) : {};
    sendGtagEvent('KYC_DONE', buildEventPayload({ utm, formType }));
};
