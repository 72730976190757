import React from 'react'
import Logo from "../../../../../assets/images/Logo.svg";
import NextButton from '../../../../../components/Buttons/NextButton';
import { useTranslation } from '../../../../../store/context/TranslationContext';
import LanguagePicker from '../../../../../components/LanguagePicker/LanguagePicker';

const AskMoreMessage = ({ title = "Individual Plus", hideContainerStyle = false, remark, data, loading, onNext = () => { } }) => {
   const {t} = useTranslation();
    return (
        <div style={hideContainerStyle ? {} : { height: "100vh", margin: "0px 30px" }} className={'d-flex justify-content-center align-items-center'}>
            <div className={` ${hideContainerStyle ? "w-100" : "form-content"}`}>

                <div className='d-flex flex-column  justify-content-center  align-items-center  '>

                    <div className='w-100 d-flex flex-column justify-content-center align-items-center  position-relative'>

                        {hideContainerStyle ? <></> : <img
                            src={Logo}
                            alt="fv-bank"
                            style={{ marginBottom: "10px" }}
                            className="logo logo-dark header-logo"
                        />}

                        {hideContainerStyle ? <></> : <div className='heading'>{t(title)}</div>}
                        <div className='language-picker-form'><LanguagePicker /></div>
                    </div>




                    <div style={{ fontSize: "30px", lineHeight: 2 }}>
                        {t("Hi")} {data?.Business_Name ? `${data?.Business_Name}` : `${data?.firstName} ${data?.lastName}`},

                    </div>
                    <div  className = "mt-3" style={{ fontSize: "20px" }}>
                        {t("To proceed with your application, we require additional information.")}
                    </div>
                    <div style={{ fontSize: "20px" }}>
                        {t("Please ensure you fill out the details requested in the following step.")}
                    </div>

         
                    <div className='mt-2 pt-2' style={{ borderTop : "1px solid #EBEBEB", fontSize: "20px" }}>
                        <b>{t("Note")} : {t(remark)} </b> 
                    </div>
                    <div className='d-flex justify-content-end w-100 mt-3'>
                        <NextButton
                            loading={loading}
                            onClick={() => {
                                onNext();

                            }}
                        />

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AskMoreMessage