import React from "react";
import { FaCheckCircle, FaCross, FaTimesCircle } from "react-icons/fa";
import "./styles.scss";

const ComparisonTable = () => {
    return (
        <div className="fv_plus_prime_container">
            <table className="fv_plus_prime_table">
                <thead>
                    <tr>
                        {/* Left column header (Features) */}
                        <th className="fv_plus_prime_th ">

                        </th>

                        {/* Middle column header (Business Plus) */}
                        <th style={{ width: "40%" }} className="fv_plus_prime_th">
                            <div className="fv_plus_prime_plan_title">Business Plus</div>
                            <p className="fv_plus_prime_plan_subtitle">
                                Suitable for SMEs requiring an operational account for managing
                                global business activities
                            </p>
                        </th>

                        {/* Right column header (Business Prime) */}
                        <th style={{ width: "40%" }} className="fv_plus_prime_th">
                            <div className="fv_plus_prime_plan_title prime">Business Prime</div>
                            <p className="fv_plus_prime_plan_subtitle prime">
                                Suitable for enterprises requiring client fund accounts,
                                settlement accounts, trade accounts, or high value transaction accounts. <br />  <br /> E.g.
                                Money Service Business, Virtual Asset Service Providers (VASPs),
                                Digital Asset Exchanges, and Trading Firms.
                            </p>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td className="feature">USD Depository Account</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Local & International Payments</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Foreign Currency Payments</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Stablecoin Deposits & Payments</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Visa Corporate Card</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Business Cards for Operators</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Digital Asset Custody</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Digital Asset-to-USD Conversion</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Transaction Limit</td>
                        <td className="text-center">Low</td>
                        <td className="text-center">High</td>
                    </tr>
                    <tr>
                        <td className="feature">Balance Threshold</td>
                        <td className="text-center">Low</td>
                        <td className="text-center">High</td>
                    </tr>
                    <tr>
                        <td className="feature">Money Market Account</td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">API Access</td>
                        <td className="text-center"><FaTimesCircle className="fv_plus_prime_wrong" /></td>
                        <td className="text-center"><FaCheckCircle className="fv_plus_prime_check" /></td>
                    </tr>
                    <tr>
                        <td className="feature">Onboarding Time</td>
                        <td className="text-center">48 - 72 hours</td>
                        <td className="text-center">Upgrade from Business Prime within 1-2 weeks</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ComparisonTable;
