import _ from "lodash";

export const REQUIRED_FIELDS = {
    1: [],
    2: [
        "Business_Name",
        "Doing_Business_As",
        "address.buildingNumber",
        "address.street",
        "address.city",
        "address.state",
        "address.pinCode",
        "address.country",
        "Business_Registration_Country",
        "entityType",
        "Business_Registration_Number",
        "Business_Formation_Date",
        "Business_Industry_Type",
        "empCount",
        "BUS_Source_Of_Funds",
        "liquidFunds",
        "hasEntity",
    ],
    3: ["Primary_Business_Activities", "Business_Product_Services", "Customer_Description", "Business_Plan_Disclosure", "Illegal_Immoral_Disclosure", "Deposit_Reasons", "Payment_Reasons", "Deposit_Locations", "Payment_Locations"],
    4: ["primaryBusinessModel", "Regulatory_Agency", "talkToSales"],
    5: [],
    6: ["hearAbtUs", "showName", "refName"],
    7: ["firstName", "lastName", "Date_Of_Birth"],
}

export const compareAddresses = (a = {}, b = {}) => {
    const keys = ['buildingNumber', 'street', 'pinCode', 'city', 'state', 'country'];
    const aPicked = _.pick(a, keys);
    const bPicked = _.pick(b, keys);

    // If either address is missing all of those keys, return false
    if (Object.keys(aPicked).length === 0 || Object.keys(bPicked).length === 0) {
        return false;
    }

    // Otherwise, compare the picked fields
    return _.isEqual(aPicked, bPicked);
};