// src/store/context/TranslationContext.js
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { doGET } from '../../util/HttpUtil';

// Create the TranslationContext with default implementations.
const TranslationContext = createContext({
  t: (key) => key,
  changeLanguage: (lang) => { },
  language: 'en',
});

export const TranslationProvider = ({ children }) => {
  // The current language (two-letter code).
  const [language, setLanguage] = useState('en');
  // Cached translations (key: translated string).
  const [translations, setTranslations] = useState({});
  // Flag to track if the grid API call is in progress.
  const [gridLoading, setGridLoading] = useState(false);
  // A Set to queue keys that need to be translated individually.
  // This Set is used both to avoid duplicate API calls and to defer on-demand calls
  // until after the grid API call has completed.
  const pendingKeysRef = useRef(new Set());
  const lastLanguageChangeRef = useRef(Date.now());

  /**
   * Helper: fetchTranslation
   * Given a key and a language, call the individual translation API.
   * If successful, update the translations cache; on failure, fallback to the key.
   */
  const fetchTranslation = useCallback(
    (key, currentLang) => {
      (async () => {
        try {
          const response = await doGET(
            `/api/o/i18n/translate?key=${encodeURIComponent(key)}&lang=${currentLang}`
          );
          // Expect response.data to be the translated string.
          const translatedText =
            response && response.data ? response.data : key;
          // Only update if the language is still current.
          if (language === currentLang) {
            setTranslations((prev) => ({ ...prev, [key]: translatedText }));
          }
        } catch (error) {
          console.error(
            `Error fetching translation for key "${key}" in language ${currentLang}:`,
            error
          );
          if (language === currentLang) {
            setTranslations((prev) => ({ ...prev, [key]: key }));
          }
        } finally {
          // Remove the key from the pending set.
          pendingKeysRef.current.delete(key);
        }
      })();
    },
    [language]
  );

  /**
   * When the language changes, we:
   * 1. Reset our translation cache and pending keys.
   * 2. If not English, call the grid API to fetch all translations.
   * 3. Once the grid call is finished, process any queued keys that are missing.
   */
  useEffect(() => {
    const currentLang = language;

    // Reset the translation cache and pending keys.
    setTranslations({});
    pendingKeysRef.current.clear();

    // For English, no API calls are needed.
    if (currentLang === 'en') {
      setGridLoading(false);
      return;
    }

    setGridLoading(true);

    (async () => {
      // We'll build our grid-translations object from the API response.
      let gridTranslations = {};
      try {
        const response = await doGET(
          `/api/o/i18n/grid?lang=${currentLang}&rows=-1`
        );
        if (response && response.data && Array.isArray(response.data.rows)) {
          response.data.rows.forEach((row) => {
            // Each row should have a row.key and row.val.
            gridTranslations[row.key] = row.val;
          });
          // Only update our state if the language hasn’t changed meanwhile.
          if (language === currentLang) {
            setTranslations(gridTranslations);
          }
        }
      } catch (error) {
        console.error(
          `Error fetching grid translations for ${currentLang}:`,
          error
        );
        // On error, leave the translations empty so that individual fetches will occur.
        if (language === currentLang) {
          setTranslations({});
        }
      } finally {
        setGridLoading(false);
        const delay = Math.max(0, 500 - (Date.now() - lastLanguageChangeRef.current));
        setTimeout(() => {
          // Process all keys that were queued while waiting for the grid.
          const pendingKeys = Array.from(pendingKeysRef.current);
          pendingKeys.forEach((key) => {
            if (!(key in gridTranslations)) {
              fetchTranslation(key, currentLang);
            }
            pendingKeysRef.current.delete(key);
          });
        }, delay);
      }
    })();
  }, [language, fetchTranslation]);

  /**
   * t() is the translation function used in the UI.
   * - For English, it immediately returns the key.
   * - For other languages:
   *   - If the translation exists in the cache, it is returned.
   *   - If not:
   *     * While the grid API is still loading, the key is queued and the fallback (English) is returned.
   *     * After grid-loading completes, if the key is still missing and not already being fetched,
   *       an individual API call is initiated.
   */
  const t = useCallback(
    (key) => {
      // For English, simply return the key.
      if (language === 'en') {
        return key;
      }

      // Return the cached translation if available.
      if (translations.hasOwnProperty(key)) {
        return translations[key];
      }

      // If the grid API is still in progress, queue the key and return fallback.
      if (gridLoading) {
        pendingKeysRef.current.add(key);
        return key;
      }

      if (Date.now() - lastLanguageChangeRef.current < 500) {
        // If within 500ms of the language change, queue the key but do not fetch yet.
        pendingKeysRef.current.add(key);
        return key;
      }

      // Grid API is done, so if we’re not already fetching this key, initiate an on-demand call.
      if (!pendingKeysRef.current.has(key)) {
        pendingKeysRef.current.add(key);
        fetchTranslation(key, language);
      }
      // Until the API returns, show the original English text.
      return key;
    },
    [language, translations, gridLoading, fetchTranslation]
  );

  /**
   * changeLanguage updates the current language.
   * When the language changes, the grid API call will automatically run.
   */
  const changeLanguage = useCallback(
    (lang) => {
      if (lang !== language) {
        lastLanguageChangeRef.current = Date.now(); // record the change time
        setLanguage(lang);
      }
    },
    [language]
  );
  
  // Memoize the context value to minimize unnecessary re-renders.
  const contextValue = useMemo(
    () => ({
      t,
      changeLanguage,
      language,
    }),
    [t, changeLanguage, language]
  );

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  );
};

// Custom hook for consuming the TranslationContext.
export const useTranslation = () => useContext(TranslationContext);
